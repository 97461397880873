<template>
  <div class="insert-attribute-value-box">
    <vs-row>
      <vs-input class="attribute-value w-full"
                :label="$t('attributes.labels.value')"
                :danger="newAttributeValue.value.length > 30"
                :danger-text="newAttributeValue.value.length > 30 ? $t('attributes.validators.value', {num: 30}) : ''"
                v-model="newAttributeValue.value"
                :autocomplete="false"
                :name="Math.random()"
                @input="handleNameChange"/>
      <vs-input class="attribute-value w-full"
                :label="$t('attributes.labels.slug')"
                :autocomplete="false"
                :name="Math.random()"
                :danger="newAttributeValue.slug.length > 30"
                :danger-text="newAttributeValue.slug.length > 30 ? $t('attributes.validators.valueSlug', {num: 30}) : ''"
                v-model="newAttributeValue.slug"/>
    </vs-row>

    <button v-show="false"
            id="saveBTN"
            @click="sendData()"/>
  </div>
</template>

<script>
export default {
  name: 'insertAttributeValue',
  props: {
    attributeId: 0,
    shops: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data () {
    return {
      newAttributeValue: {
        value: '',
        slug: ''
      }
    }
  },
  methods: {
    handleNameChange () {
      this.newAttributeValue.slug = this.newAttributeValue.value.toLowerCase().replaceAll(' ', '-')
    },
    sendData () {
      if (this.newAttributeValue.value.length > 30) {
        this.$vs.notify({
          title: this.$t('alert.error.title'),
          text: this.$t('attributes.validators.value', {num: 30}),
          time: 2400,
          color: 'danger',
          icon: 'icon-alert-circle',
          iconPack: 'feather'
        })
        return false
      }

      if (this.newAttributeValue.slug.length > 30) {
        this.$vs.notify({
          title: this.$t('alert.error.title'),
          text: this.$t('attributes.validators.valueSlug', {num: 30}),
          time: 2400,
          color: 'danger',
          icon: 'icon-alert-circle',
          iconPack: 'feather'
        })
        return false
      }

      if (this.newAttributeValue.slug.length === 0) {
        this.$vs.notify({
          title: this.$t('alert.error.title'),
          text: this.$t('attributes.validators.slugRequired'),
          time: 2400,
          color: 'danger',
          icon: 'icon-alert-circle',
          iconPack: 'feather'
        })
        return false
      }

      this.$emit('inserted', this.newAttributeValue)
    }
  }
}
</script>

<style scoped>

</style>
