<template>
  <div class="select-attributes-list">
    <draggable-dynamic-table ref="selectAttributesList"
                             :in-modal="true"
                             :options="options"
                             :columns="columnsLabel"
                             @filter:remove="setFilter($event, true)"
                             @filter:set="setFilter($event)"
                             @column:select="selectedRows = $event"
                             @load:more="getAttributes()"
                             v-model="data"/>

    <vs-button id="selectAttributes" class="useral-action-button" @click="$emit('selected', selectedRows)"/>

  </div>
</template>

<script>
import {getAllCategoryAttributes} from '@/http/requests/products'
import InsertAttributes from '@/components/categoryActions/insertAttributes'
import {getAttributes} from "../../../../../http/requests/attributes";

export default {
  name: 'selectAttributesList',
  components: {InsertAttributes},
  props: {
    categoryId: {
      type: Number,
      default: 0
    },
    insertStatus: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      options: {
        id: 'SelectAttributesListTable',
        rowKeyField: 'id'
      },
      columnsLabel: [
        {
          field: 'name',
          i18n: 'attributes.select.table.header.name',
          locked: true,
          width: 'calc(100% - 50px)',
          minWidth: 150,
          maxWidth: 300,
          filter: true,
          filterTypes: [
            {
              icon: 'search',
              name: 'شامل شود',
              i18n: 'draggableTable.filter.types.search',
              id: 1
            }
          ],
        },
        {
          field: 'select',
          i18n: 'attributes.select.table.header.select',
          width: '50px',
          minWidth: 50,
          align: 'center',
          selectable: true,
          locked: true,
          fixed: true,
          footer: {}
        }
      ],
      data: [],
      selectedRows: [],
      filters: [],
      page: 1,
      total_count: null,
      loadingTimer: 0,
      attributePromptStatus: false,
      attribute: {
        name: '',
        values: []
      },
      actions: [
        {
          toolbar: [
            {
              id: 'InsertAttribute',
              color: 'success',
              icon: 'icon-plus',
              iconPack: 'feather'
            }
          ],
          leftToolbar: [
            {
              id: 'printTable',
              icon: 'icon-printer',
              iconPack: 'feather'
            },
            {
              id: 'downloadTable',
              icon: 'icon-download',
              iconPack: 'feather'
            },
            {
              id: 'settingTable',
              icon: 'icon-settings',
              iconPack: 'feather'
            }
          ]
        }
      ]
    }
  },
  created () {

    this.getAttributes()
  },
  methods: {
    getAttributes () {
      clearTimeout(this.loadingTimer)
      this.loadingTimer = setTimeout(() => {
        if (this.data.length < this.total_count || !this.total_count) {
          if (this.$refs.selectAttributesList && this.data.length === 0) this.$refs.selectAttributesList.loadMoreStatus = 'FirstLoad'
          else if (this.$refs.selectAttributesList && this.data.length > 0) this.$refs.selectAttributesList.loadMoreStatus = 'Loading'

          this.data = this.selectedRows
          if (!this.insertStatus) {
            if (this.data.length === 0) {
              getAllCategoryAttributes(this.categoryId, this.filters, ['type=create']).then((response) => {
                const attributes = response.data.data

                attributes.forEach((attribute) => {
                  const data = {
                    id: attribute.id,
                    name: attribute.name.toString().replaceAll('-', ' '),
                    values: attribute.values.map(elm => {
                      return elm.value
                    }).join(', ')
                  }
                  this.data.push(data)
                })

                const row_index = this.columnsLabel.map((e) => {
                  return e.field
                }).indexOf('select')
                this.columnsLabel[row_index].footer.value = attributes.length

                if (this.$refs.selectAttributesList) this.$refs.selectAttributesList.loadMoreStatus = ''
              }).catch((error) => {
                console.log(error)
                if (this.$refs.selectAttributesList) this.$refs.selectAttributesList.loadMoreStatus = 'Danger'
              })
            } else {
              if (this.$refs.selectAttributesList) this.$refs.selectAttributesList.loadMoreStatus = ''
            }
          } else {
            getAttributes(this.page, this.filters).then((response) => {
              const attributes = response.data.data

              attributes.forEach((attribute) => {
                const data = {
                  id: attribute.id,
                  name: attribute.name.toString().replaceAll('-', ' '),
                  values: attribute.values.map(elm => {
                    return elm.value
                  }).join(', ')
                }
                this.data.push(data)
              })

              this.page = response.data.pagination.current_page + 1
              this.total_count = response.data.pagination.total
              const row_index = this.columnsLabel.map((e) => {
                return e.field
              }).indexOf('select')
              this.columnsLabel[row_index].footer.value = attributes.length

              if (this.$refs.selectAttributesList) this.$refs.selectAttributesList.loadMoreStatus = ''
            }).catch(() => {
              if (this.$refs.selectAttributesList) this.$refs.selectAttributesList.loadMoreStatus = 'Danger'
            })
          }
        }
      }, 300)
    },
    setFilter (filters, remove) {
      const filters_list = []
      Object.keys(filters).forEach((key) => {
        switch (key) {

          case 'name':
            if (filters[key].search !== '') filters_list.push(`name=${  filters[key].search}`)
            break
        }
      })

      this.data = []
      this.page = 1
      this.filters = filters_list
      this.$nextTick(() => {
        this.getAttributes()
      })
    },
    handleClick (id) {
      document.getElementById(id).click()
    },
    refreshPage () {
      this.page = 1
      this.data = []
      this.$nextTick(() => {
        this.getAttributes()
      })
      this.attributePromptStatus = false
    }
  },
  watch: {
    '$store.state.helper.attributesChanged': {
      handler (val) {
        if (val) {
          this.refreshPage()
        }
      },
      deep: true
    }
  }
}
</script>

<style lang="scss">
.select-attributes-list {
  height: 100%;
}
</style>
