<template>
  <div class="select-products-list">
    <draggable-dynamic-table ref="selectProductsListTable"
                             :data="data"
                             :columns="columnsLabel"
                             :options="options"
                             :in-modal="true"
                             @filter:remove="setFilter($event, true)"
                             @filter:set="setFilter($event)"
                             @sort:set="setSort($event)"
                             @column:select="selectedRows = $event, checkStatus($event)"
                             @product:edit="selectedProduct = $event, editProductPromptStatus = true"
                             @load:more="getProducts()"/>

    <!-- edit product prompt -->
    <vs-prompt
      class="medium-prompt p-0"
      :buttons-hidden="true"
      title=""
      :active.sync="editProductPromptStatus">

      <div class="prompt-header p-3 w-full" :class="[!this.$vs.rtl ? 'rtl-only' : '']">
        <vs-row>
          <vs-col class="w-1/5 useral-font-weight-medium text-success cursor-pointer">
            <div @click="handleClick('editProductBTN')">
              <custom-icon icon="CHECK" color="success"/>
            </div>
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/2 text-center useral-font-weight-bold text-md">
            {{ selectedProduct ? $t('products.edit.dynamicTitle', {name: selectedProduct.name}) : $t('products.edit.title') }}
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/5 text-right useral-font-weight-medium text-danger cursor-pointer">
            <div @click="editProductPromptStatus = false">
              <custom-icon icon="TIMES-CIRCLE" color="danger"/>
            </div>
          </vs-col>
        </vs-row>
      </div>

      <div class="prompt-content p-2">
        <template>
          <edit-product :product-id="selectedProduct.productId"/>
        </template>
      </div>

    </vs-prompt>

    <vs-button id="selectProducts" class="useral-action-button" @click="$emit('selected', selectedRows)"/>
  </div>
</template>

<script>
import {addComma} from '@/assets/js/functions'
import {getCategories, getShreddedProducts} from '@/http/requests/products'
import {getStores} from '@/http/requests/stores'
import EditProduct from '@/views/admin/products/productsList/edit/editProduct'
import CustomIcon from '@/components/customIcon/customIcon'
import {customParseFloat, getStaticFileUrl} from "../../../../../assets/js/functions";

export default {
  name: 'selectProducts',
  components: {
    CustomIcon,
    EditProduct
  },
  props: {
    storeroom: {
      Type: Boolean,
      default: false
    },
    multiSelect: {
      type: Boolean,
      default: () => { return true }
    },
    storeroomId: 0,
    openingBalance: 0,
    customer: 0,
    supplier: 0
  },
  data () {
    return {
      selectedRows: [],
      selectedProduct: {},
      editProductPromptStatus: false,
      insertPromptStatus: false,
      loadingTimer: 0,
      checkStatusTimer: 0,
      selectedBanks: [],
      colors: {},
      options: {
        rowKeyField: 'id'
      },
      columnsLabel: [
        {
          field: 'edit',
          i18n: 'products.select.table.header.edit',
          width: '70px',
          minWidth: 70,
          /*sortable: true,*/
          actions: true,
          showAction: 'always',
          action: {
            color: 'warning',
            icon: 'icon-edit-2',
            iconPack: 'feather',
            type: 'button'
          },
          event: 'product:edit'
        },
        {
          field: 'storeroom',
          i18n: 'products.select.table.header.storeroom',
          width: '200px',
          minWidth: 200,
          align: 'center',
          filter: true,
          filterType: 'checkbox',
          filterTypes: []
          // sortable: true,
          // locked: true,
        },
        {
          field: 'quantity',
          i18n: 'products.select.table.header.quantity',
          width: '100px',
          minWidth: 100,
          align: 'center'
          // sortable: true,
          // locked: true,
        },
        {
          field: 'salePrice',
          i18n: 'products.select.table.header.salePrice',
          description: this.$locale.currency(),
          width: '150px',
          minWidth: 150,
          align: 'right',
          filter: true,
          filterTypes: [
            {
              icon: 'equals',
              name: 'برابر باشد',
              i18n: 'draggableTable.filter.types.equals',
              id: 1
            },
            {
              icon: 'not-equal',
              name: 'برابر نباشد',
              i18n: 'draggableTable.filter.types.notEqual',
              id: 2
            },
            {
              icon: 'greater-than-equal',
              name: 'بزرگتر مساوی',
              i18n:
                'draggableTable.filter.types.greaterThanEquals',
              id: 3
            },
            {
              icon: 'less-than-equal',
              name: 'کوچکتر مساوی',
              i18n: 'draggableTable.filter.types.lessThanEquals',
              id: 4
            }
          ],
          sortable: true
        },
        {
          field: 'unitPrice',
          i18n: 'products.select.table.header.price',
          description: this.$locale.currency(),
          width: '150px',
          minWidth: 150,
          align: 'right',
          filter: true,
          filterTypes: [
            {
              icon: 'equals',
              name: 'برابر باشد',
              i18n: 'draggableTable.filter.types.equals',
              id: 1
            },
            {
              icon: 'not-equal',
              name: 'برابر نباشد',
              i18n: 'draggableTable.filter.types.notEqual',
              id: 2
            },
            {
              icon: 'greater-than-equal',
              name: 'بزرگتر مساوی',
              i18n:
                'draggableTable.filter.types.greaterThanEquals',
              id: 3
            },
            {
              icon: 'less-than-equal',
              name: 'کوچکتر مساوی',
              i18n: 'draggableTable.filter.types.lessThanEquals',
              id: 4
            }
          ],
          sortable: true
        },
        {
          field: 'purchasePrice',
          i18n: 'products.select.table.header.purchasePrice',
          description: this.$locale.currency(),
          width: '250px',
          minWidth: 250,
          align: 'right',
          filter: true,
          filterTypes: [
            {
              icon: 'equals',
              name: 'برابر باشد',
              i18n: 'draggableTable.filter.types.equals',
              id: 1
            },
            {
              icon: 'not-equal',
              name: 'برابر نباشد',
              i18n: 'draggableTable.filter.types.notEqual',
              id: 2
            },
            {
              icon: 'greater-than-equal',
              name: 'بزرگتر مساوی',
              i18n:
                'draggableTable.filter.types.greaterThanEquals',
              id: 3
            },
            {
              icon: 'less-than-equal',
              name: 'کوچکتر مساوی',
              i18n: 'draggableTable.filter.types.lessThanEquals',
              id: 4
            }
          ],
          sortable: true
        },
        {
          field: 'type',
          i18n: 'products.select.table.header.type',
          width: '100px',
          minWidth: 100,
          filter: true,
          align: 'center',
          filterType: 'select',
          filterTypes: [
            {
              label: this.$t('products.select.table.filters.type.all'),
              value: '0'
            },
            {
              label: this.$t('products.select.table.filters.type.simple'),
              value: '1'
            },
            {
              label: this.$t('products.select.table.filters.type.variant'),
              value: '2'
            }
          ],
          sortable: true
          // locked: true,
        },
        {
          field: 'variant',
          i18n: 'products.select.table.header.variant',
          width: 'calc(25%)',
          minWidth: 150,
          align: 'center',
          filter: true,
          filterTypes: [
            {
              icon: 'search',
              name: 'شامل شود',
              i18n: 'draggableTable.filter.types.search',
              id: 0
            },
            {
              icon: 'equals',
              name: 'برابر باشد',
              i18n: 'draggableTable.filter.types.equals',
              id: 1
            },
            {
              icon: 'not-equal',
              name: 'برابر نباشد',
              i18n: 'draggableTable.filter.types.notEqual',
              id: 2
            }
          ],
          // sortable: true
          // locked: true,
        },
        {
          field: 'name',
          i18n: 'products.select.table.header.name',
          width: 'calc(75%)',
          minWidth: 400,
          align: 'left',
          ellipsis: true,
          filter: true,
          filterTypes: [
            {
              icon: 'search',
              name: 'شامل شود',
              i18n: 'draggableTable.filter.types.search',
              id: 0
            }
          ],
          sortable: true
          // locked: true,
        },
        {
          field: 'category',
          i18n: 'products.select.table.header.category',
          width: '200px',
          minWidth: 200,
          align: 'center',
          filter: true,
          filterType: 'checkbox',
          filterTypes: []
          // sortable: true,
          // locked: true,
        },
        {
          field: 'barcode',
          i18n: 'products.select.table.header.barcode',
          width: '180px',
          minWidth: 180,
          align: 'center',
          filter: true,
          filterTypes: [
            {
              icon: 'search',
              name: 'شامل شود',
              i18n: 'draggableTable.filter.types.search',
              id: 0
            }
          ],
          sortable: true
        },
        {
          field: 'code',
          i18n: 'products.select.table.header.code',
          width: '100px',
          minWidth: 100,
          align: 'center',
          filter: true,
          filterTypes: [
            {
              icon: 'search',
              name: 'شامل شود',
              i18n: 'draggableTable.filter.types.search',
              id: 0
            }
          ],
          sortable: true
        },
        {
          field: 'image',
          i18n: 'products.select.table.header.image',
          // locked: true,
          width: '90px',
          minWidth: 90
        },
        {
          field: 'rowNumber',
          i18n: 'products.select.table.header.rowNumber',
          // sortable: true,
          // locked: true,
          fixed: true,
          align: 'center',
          width: '70px',
          minWidth: 70,
          footer: {}
        },
        {
          field: 'select',
          i18n: 'products.select.table.header.select',
          width: '50px',
          minWidth: 50,
          align: 'center',
          selectable: true,
          multiSelect: this.multiSelect,
          locked: true,
          fixed: true
        }
      ],
      data: [],
      filters: [],
      sorts: [],
      page: 1,
      total_count: 1,
      endedList: false
    }
  },
  created () {
    if (this.storeroom || this.storeroomId > 0 || this.customer > 0 || this.supplier > 0) {
      this.columnsLabel.splice(this.columnsLabel.map(elm => {
        return elm.field
      }).indexOf('code'), 1)
      this.getStorerooms()
    } else {
      this.columnsLabel.splice(this.columnsLabel.map(elm => {
        return elm.field
      }).indexOf('storeroom'), 1)
      this.columnsLabel.splice(this.columnsLabel.map(elm => {
        return elm.field
      }).indexOf('quantity'), 1)
    }

    if (this.openingBalance > 0) {
      this.filters = [`openingInventory=${  this.openingBalance}`]
    }

    if (this.storeroomId > 0 && this.storeroom) {
      this.filters = [`storeroomId=${  this.storeroomId}`]
    }

    if (this.customer > 0) {
      this.filters = [`customer=${  this.customer}`]
    } else if (this.supplier > 0) {
      this.filters = [`supplier=${  this.supplier}`]
    }

    this.getProducts()
    this.getCategories()
  },
  methods: {
    addComma,
    handleClick (id) {
      const element = document.getElementById(id)
      if (element) element.click()
    },
    checkStatus (selected) {
      if (!this.multiSelect) {
        clearTimeout(this.checkStatusTimer)
        this.checkStatusTimer = setTimeout(() => {
          selected.forEach((select) => {
            if (select.show) this.$emit('selected', this.selectedRows)
          })
        }, 200)
      }
    },
    getProducts () {
      clearTimeout(this.loadingTimer)
      this.loadingTimer = setTimeout(() => {
        if (this.data.length < this.total_count || !this.total_count) {
          if (this.$refs.selectProductsListTable && this.data.length === 0) this.$refs.selectProductsListTable.loadMoreStatus = 'FirstLoad'
          else if (this.$refs.selectProductsListTable && this.data.length > 0) this.$refs.selectProductsListTable.loadMoreStatus = 'Loading'

          this.data = this.selectedRows
          getShreddedProducts(this.page, this.filters, this.sorts, this.storeroom).then((response) => {
            const products = response.data

            products.data.forEach(product => {
              const variables = product.variables.map(elm => {
                return `${elm.name.replaceAll('-', ' ')}: ${elm.value.replaceAll('-', '/')}`
              })
              const data = {
                id: this.storeroom ? parseInt(`${product.id}${product.storeroom.id}`) : product.id,
                variant_id: product.id,
                rowNumber: this.data.length + 1,
                image: {
                  type: 'img',
                  src: product.product.image ? product.product.image : getStaticFileUrl('/images/logo.png'),
                  class: 'product-image'
                },
                productId: product.product.id,
                code: product.product.code,
                barcode: product.barcode,
                salePrice: {value: customParseFloat(product.sale_price) >= 0 ? product.sale_price : '', type: 'price'},
                unitPrice: {value: product.price, type: 'price'},
                purchasePrice: {
                  value: customParseFloat(product.purchase_price) >= 0 ? product.purchase_price : '',
                  type: 'price'
                },
                fullName: `${product.product.title}${variables.length > 0 ? ' - ' : ''}${variables.join(', ')}`,
                name: `${product.product.title}`,
                variant: variables.length > 0 ? variables.join(', ') : '-',
                category: product.product.category.name,
                type: product.product.type === 1 ? this.$t('products.product.type.simple') : product.product.type === 2 ? this.$t('products.product.type.variant') : this.$t('products.product.type.service'),
                styles: product.product.type === 1 || product.product.type === 3 ? '' : `background: ${this.colors.hasOwnProperty(product.product.code) ? this.colors[product.product.code] : this.getRandomColor(product.product.code)}`
              }

              if (this.storeroom || this.storeroomId > 0 || this.supplier > 0) {
                data.quantity = product.quantity
                data.storeroom = {
                  id: product.storeroom ? product.storeroom.id : null,
                  value: product.storeroom ? product.storeroom.name : '-'
                }
              }

              if (this.customer > 0) {
                data.quantity = product.quantity * -1
                data.storeroom = {
                  id: product.storeroom ? product.storeroom.id : null,
                  value: product.storeroom ? product.storeroom.name : '-'
                }
              }

              this.data.push(data)
            })

            this.total_count = products.pagination.total
            this.page = products.pagination.current_page + 1

            if (response.data.pagination.current_page === 1) {
              const row_index = this.columnsLabel.map((e) => {
                return e.field
              }).indexOf('rowNumber')
              this.columnsLabel[row_index].footer.value = response.data.pagination.total
            }

            if (this.$refs.selectProductsListTable) this.$refs.selectProductsListTable.loadMoreStatus = ''

          }).catch((error) => {
            console.log(error)

            if (this.$refs.selectProductsListTable) this.$refs.selectProductsListTable.loadMoreStatus = 'Danger'
          })
        }
      }, 300)
    },
    getCategories () {
      getCategories(true).then(response => {
        const categories = []
        response.data.data.forEach((category) => {
          categories.push({
            id: category.id,
            label: category.name,
            value: category.id,
            show: false
          })
        })

        this.columnsLabel[this.columnsLabel.map(elm => {
          return elm.field
        }).indexOf('category')].filterTypes = categories
      })
    },
    getStorerooms () {
      getStores(true).then(response => {
        const stores = []
        response.data.data.forEach((store) => {
          stores.push({
            id: store.id,
            label: store.name,
            value: store.id,
            show: false
          })
        })

        this.columnsLabel[this.columnsLabel.map(elm => {
          return elm.field
        }).indexOf('storeroom')].filterTypes = stores
      })
    },
    getRandomColor (code) {
      const letters = 'BCDEF'.split('')
      let color = '#'
      for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * letters.length)]
      }
      color += '80'
      this.colors[code] = color
      return color
    },
    setFilter (filters) {
      const filters_list = []
      Object.keys(filters).forEach((key) => {
        switch (key) {

        case 'variant':
          if (filters[key].search !== '') filters_list.push(`variant=${  filters[key].search  }`)
          break

        case 'code':
          if (filters[key].search !== '') filters_list.push(`code=${  filters[key].search}`)
          break

        case 'barcode':
          if (filters[key].search !== '') filters_list.push(`barcode=${  filters[key].search}`)
          break

        case 'unitPrice':
          if (filters[key].search !== '') filters_list.push(`price=${  filters[key].search  },${  filters[key].type.id}`)
          break

        case 'purchasePrice':
          if (filters[key].search !== '') filters_list.push(`purchase_price=${  filters[key].search  },${  filters[key].type.id}`)
          break

        case 'category':
          if (filters[key].search.length) {
            const categories_id = filters[key].search.map(elm => {
              return elm.show ? elm.id : null
            }).filter(elm => {
              return elm !== null
            }).join('_')

            if (categories_id !== '') filters_list.push(`category=${  categories_id}`)
          }
          break

        case 'name':
          if (filters[key].search !== '') filters_list.push(`title=${  filters[key].search}`)
          break

        case 'type':
          filters_list.push(`type=${  filters[key].search.value > 0 && filters[key].search.value === 1 ? 'simple' : 'variable'}`)
          break
        }
      })

      if (this.openingBalance > 0) {
        filters_list.push(`openingInventory=${  this.openingBalance}`)
      }

      if (this.storeroomId > 0) {
        filters_list.push(`storeroomId=${  this.storeroomId}`)
      }

      if (this.customer > 0) {
        filters_list.push(`customer=${  this.customer}`)
      } else if (this.supplier > 0) {
        filters_list.push(`supplier=${  this.supplier}`)
      }

      if (this.filters.join(',') !== filters_list.join(',')) {
        this.data = []
        this.page = 1
        this.endedList = false
        this.filters = filters_list

        this.$nextTick(() => {
          this.getProducts()
        })
      }
    },
    setSort (sorts) {
      const sorts_list = []
      Object.keys(sorts).forEach((key) => {
        switch (key) {

        case 'rowNumber':
          sorts_list.push(`order[0]=id,${  sorts[key] ? 'desc' : 'asc'}`)
          break

        case 'code':
          sorts_list.push(`order[0]=code,${  sorts[key] ? 'desc' : 'asc'}`)
          break

        case 'barcode':
          sorts_list.push(`order[0]=barcode,${  sorts[key] ? 'desc' : 'asc'}`)
          break

        case "category":
            sorts_list.push("order[0]=category," + (sorts[key] ? 'desc' : 'asc'))
          break;

        case 'name':
          sorts_list.push(`order[0]=title,${  sorts[key] ? 'desc' : 'asc'}`)
          break

        case 'type':
          sorts_list.push(`order[0]=type,${  sorts[key] ? 'desc' : 'asc'}`)
          break

        case 'variant':
          sorts_list.push(`order[0]=variant,${  sorts[key] ? 'desc' : 'asc'}`)
          break

        case 'unitPrice':
          sorts_list.push(`order[0]=price,${  sorts[key] ? 'desc' : 'asc'}`)
          break

        case 'salePrice':
          sorts_list.push(`order[0]=sale_price,${  sorts[key] ? 'desc' : 'asc'}`)
          break

        case 'purchasePrice':
          sorts_list.push(`order[0]=purchase_price,${  sorts[key] ? 'desc' : 'asc'}`)
          break
        }
      })

      this.data = []
      this.page = 1
      this.endedList = false
      this.sorts = sorts_list
      this.$nextTick(() => {
        this.getProducts()
      })
    }
  },
  watch: {
    '$store.state.productsChanged': {
      handler (val) {
        if (val) {
          this.editProductPromptStatus = false
        }
      },
      deep: true
    },
    storeroom: {
      handler () {
        this.filters = []
        this.data = []
        this.page = 1
        this.endedList = false
        if (this.openingBalance > 0) {
          this.filters = [`openingInventory=${  this.openingBalance}`]
        }

        if (this.storeroomId > 0 && this.storeroom) {
          this.filters = [`storeroomId=${  this.storeroomId}`]
        }

        if (this.customer > 0) {
          this.filters = [`customer=${  this.customer}`]
        } else if (this.supplier > 0) {
          this.filters = [`supplier=${  this.supplier}`]
        }
        this.$nextTick(() => {
          this.getProducts()
        })
      }
    }
  }
}
</script>

<style lang="scss">
.select-products-list {
  height: 100%;


  .draggable-dynamic-table {

    .product-image {
      width: 50px;
      height: 50px;
      object-fit: cover;
      margin: 5px auto;
      display: block;
      border-radius: 10px;
    }

  }
}
</style>
