<template>
  <vx-card class="edit-product" :class="{'main-box top-zero-radius md:w-3/4 m-0 px-3': !productId, 'no-box-shadow': productId > 0}">

    <useral-edit-product v-if="selectedIEditType === 'USERAL'"
                         :product-id="productId || this.$route.params.id"/>

    <woocommerce-edit-product v-else-if="selectedIEditType !== null"
                              :shop-id="selectedIEditType"
                              :product-id="this.receivedId"/>
  </vx-card>
</template>

<script>
import axios from 'axios'
import CustomIcon from '../../../../../components/customIcon/customIcon'
import {getProductStatus} from '../../../../../http/requests/products'
import {showLoading} from "../../../../../assets/js/functions";

export default {
  name: 'editProduct',
  metaInfo () {
    return {
      title: this.$t('products.edit.title')
    }
  },
  components: {
    CustomIcon,
    UseralEditProduct: () => import('./UseralEditProduct'),
    WoocommerceEditProduct: () => import('./WoocommerceEditProduct')
  },
  data () {
    return {
      selectEditTypeStatusPrompt: false,
      showErrorNotificationTimer: 0,
      selectedIEditType: null,
      receivedId: 0
    }
  },
  props: {
    productId: 0
  },
  created () {
    this.$nextTick(() => {
      showLoading()
    })

    this.checkStatus()
  },
  methods: {
    checkStatus () {
      getProductStatus(this.productId || this.$route.params.id).then(response => {
        const status = response.data.data
        if (status.received && status.received.shop && status.received.shop.id && status.received.synced) {
          this.selectedIEditType = status.received.shop.id
          this.receivedId = status.received.received_id
        } else {
          this.selectedIEditType = 'USERAL'
        }
      })
        .catch(error => {
          if (axios.isCancel(error)) {
            this.$vs.notify({
              title: this.$t('alert.duplicateRequest.title'),
              text: this.$t('alert.duplicateRequest.message'),
              icon: 'icon-alert-circle',
              iconPack: 'feather',
              time: 2400,
              color: 'warning'
            })
          }
        })
    }
  }
}
</script>

<style lang="scss">
.select-type-button {
  width: 100%;
  line-height: 40px;
  border: 1px solid #cecece;
  border-radius: .5rem;
  cursor: pointer;
  transition: all .3s ease;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  &.useral {
    background: #83bcff;
    border-color: #479fff;
    color: #ffffff;

    &:hover {
      box-shadow: 0 0 15px -2px #83bcff;
    }
  }

  &.woocommerce {
    background: #945c87;
    border-color: #6d285d;
    color: #ffffff;

    &:hover {
      box-shadow: 0 0 15px -2px #6d285d;
    }
  }

  &:hover {
    box-shadow: 0 0 15px -2px #cecece;
  }
}

.edit-product {
  height: 100%;
}
</style>
