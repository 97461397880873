<template>
  <div class="edit-users-opening-balance">

    <div class="edit-users-opening-balance-box">

      <vs-row class="my-2">
        <vs-col class="w-full">
          <vs-input class="number-style w-full"
                    v-model="updatedUsers.price"
                    :autocomplete="false"
                    :name="Math.random()"
                    :label="`${$t('users.openingBalance.edit.labels.price')}`"/>
        </vs-col>
      </vs-row>

      <vs-row class="my-2">
        <vs-col class="w-full">
          <vs-button class="w-full"
                     color="danger"
                     @click="$refs.deleteOpeningBalanceConfirmation.showDialog()">
            {{ $t('users.openingBalance.edit.labels.delete') }}
          </vs-button>
        </vs-col>
      </vs-row>
    </div>

    <custom-dialog ref="deleteOpeningBalanceConfirmation"
                   :title="`${$t('users.openingBalance.edit.confirmations.delete.title')}`"
                   :body="`${$t('users.openingBalance.edit.confirmations.delete.body')}`"
                   @accept="handleDeleteUserOpeningBalance"/>

    <button v-show="false" id="saveBTN" @click="sendData"/>
  </div>
</template>

<script>
import axios from 'axios'
import {addComma} from '@/assets/js/functions'
import CustomIcon from '../../../../components/customIcon/customIcon'
import {
  deleteDebtorUsersOpeningInventories,
  deleteCreditorUsersOpeningInventories,
  setDebtorUsersOpeningInventories,
  setCreditorUsersOpeningInventories
} from '../../../../http/requests/openingInventories'
import CustomDialog from '../../../../components/customDialog/customDialog'
import {customParseFloat} from "../../../../assets/js/functions";

export default {
  name: 'editUsersOpeningBalance',
  components: {CustomDialog, CustomIcon},
  props: {
    user: {},
    type: {
      type: String,
      default: 'debtor'
    }
  },
  data () {
    return {
      updatedUsers: {}
    }
  },
  created () {
    this.updatedUsers = JSON.parse(JSON.stringify(this.user))
  },
  methods: {
    customParseFloat,
    sendData () {
      const user = {
        id: this.updatedUsers.id,
        price: customParseFloat(this.updatedUsers.price.toString().replaceAll(',', ''))
      }
      if (this.type === 'debtor') {
        setDebtorUsersOpeningInventories([user]).then(() => {
          this.$vs.notify({
            title: this.$t('users.openingBalance.edit.notifications.debtor.update.success.title'),
            text: this.$t('users.openingBalance.edit.notifications.debtor.update.success.message'),
            color: 'success',
            time: 2400,
            icon: 'icon-check',
            iconPack: 'feather'
          })

          this.$store.dispatch('helper/changeOpeningBalance')
        }).catch((error) => {
          if (axios.isCancel(error)) {
            this.$vs.notify({
              title: this.$t('alert.duplicateRequest.title'),
              text: this.$t('alert.duplicateRequest.message'),
              icon: 'icon-alert-circle',
              iconPack: 'feather',
              time: 2400,
              color: 'warning'
            })
          } else {
            this.$vs.notify({
              title: this.$t('users.openingBalance.edit.notifications.debtor.update.error.title'),
              text: this.$t('users.openingBalance.edit.notifications.debtor.update.error.message'),
              color: 'danger',
              time: 2400,
              icon: 'icon-alert-circle',
              iconPack: 'feather'
            })
          }
        })
      } else {
        setCreditorUsersOpeningInventories([user]).then(() => {
          this.$vs.notify({
            title: this.$t('users.openingBalance.edit.notifications.creditor.update.success.title'),
            text: this.$t('users.openingBalance.edit.notifications.creditor.update.success.message'),
            color: 'success',
            time: 2400,
            icon: 'icon-check',
            iconPack: 'feather'
          })

          this.$store.dispatch('helper/changeOpeningBalance')
        }).catch((error) => {
          if (axios.isCancel(error)) {
            this.$vs.notify({
              title: this.$t('alert.duplicateRequest.title'),
              text: this.$t('alert.duplicateRequest.message'),
              icon: 'icon-alert-circle',
              iconPack: 'feather',
              time: 2400,
              color: 'warning'
            })
          } else {
            this.$vs.notify({
              title: this.$t('users.openingBalance.edit.notifications.creditor.update.error.title'),
              text: this.$t('users.openingBalance.edit.notifications.creditor.update.error.message'),
              color: 'danger',
              time: 2400,
              icon: 'icon-alert-circle',
              iconPack: 'feather'
            })
          }
        })
      }
    },
    handleDeleteUserOpeningBalance () {
      if (this.user && this.user.id) {
        if (this.type === 'debtor') {
          deleteDebtorUsersOpeningInventories(this.user.id).then(() => {
            this.$vs.notify({
              title: this.$t('users.openingBalance.edit.notifications.debtor.delete.success.title'),
              text: this.$t('users.openingBalance.edit.notifications.debtor.delete.success.message'),
              color: 'success',
              time: 2400,
              icon: 'icon-check',
              iconPack: 'feather'
            })

            this.$store.dispatch('helper/deleteOpeningBalance')
          }).catch((error) => {
            if (axios.isCancel(error)) {
              this.$vs.notify({
                title: this.$t('alert.duplicateRequest.title'),
                text: this.$t('alert.duplicateRequest.message'),
                icon: 'icon-alert-circle',
                iconPack: 'feather',
                time: 2400,
                color: 'warning'
              })
            } else {
              this.$vs.notify({
                title: this.$t('users.openingBalance.edit.notifications.debtor.delete.error.title'),
                text: this.$t('users.openingBalance.edit.notifications.debtor.delete.error.message'),
                color: 'danger',
                time: 2400,
                icon: 'icon-alert-circle',
                iconPack: 'feather'
              })
            }
          })
        } else {
          deleteCreditorUsersOpeningInventories(this.user.id).then(() => {
            this.$vs.notify({
              title: this.$t('users.openingBalance.edit.notifications.creditor.delete.success.title'),
              text: this.$t('users.openingBalance.edit.notifications.creditor.delete.success.message'),
              color: 'success',
              time: 2400,
              icon: 'icon-check',
              iconPack: 'feather'
            })

            this.$store.dispatch('helper/deleteOpeningBalance')
          }).catch((error) => {
            if (axios.isCancel(error)) {
              this.$vs.notify({
                title: this.$t('alert.duplicateRequest.title'),
                text: this.$t('alert.duplicateRequest.message'),
                icon: 'icon-alert-circle',
                iconPack: 'feather',
                time: 2400,
                color: 'warning'
              })
            } else {
              this.$vs.notify({
                title: this.$t('users.openingBalance.edit.notifications.creditor.delete.error.title'),
                text: this.$t('users.openingBalance.edit.notifications.creditor.delete.error.message'),
                color: 'danger',
                time: 2400,
                icon: 'icon-alert-circle',
                iconPack: 'feather'
              })
            }
          })
        }
      }
    }
  },
  watch: {
    'updatedUsers.price': {
      handler (val) {
        this.updatedUsers.price = addComma(val)
      }
    }
  }
}
</script>

<style lang="scss">
.select-file-input {
  width: 100%;
  background: #28c76f;
  display: block;
  padding: 10px;
  border-radius: .5rem;

  span {
    color: white !important;

    div.icon {
      background-color: white !important;
    }
  }

  input {
    display: none;
  }
}
</style>
