/*=========================================================================================
  File Name: moduleAuthMutations.js
  Description: Auth Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import axios from '@/http/axios/index.js'
import {deleteCookie} from "../../assets/js/functions";
import {logoutUser} from "../../http/requests/auth";

export default {
  // /////////////////////////////////////////////
  // User/Account
  // /////////////////////////////////////////////

  // Updates user info in state and localstorage
  UPDATE_USER_INFO (state, payload) {
    state.user = payload
  },

  // Updates user permission in state and localstorage
  UPDATE_USER_PERMISSION (state, payload) {
    state.user.permissions = payload
  },

  // Updates user permission in state and localstorage
  UPDATE_USER_FINANCIAL_YEAR (state, payload) {
    state.user.financial_year = payload
  },

  LOGOUT_USER (state) {
    // remove user info from local storage
    state.user = {}
    // remove access token from local storage
    deleteCookie('accessToken')
    localStorage.removeItem('accessToken')
    localStorage.removeItem('expired_at')
    logoutUser()
  },

  NO_ACCESS_USER (state) {
    if (state.user.role.id !== 1) {
      // remove user info from local storage
      state.user = {}
      // remove access token from local storage
      deleteCookie('accessToken')
      localStorage.removeItem('accessToken')
      localStorage.removeItem('expired_at')
      logoutUser()
    }
  },

  // store access token in local storage
  SET_ACCESS_TOKEN (state, payload) {
    localStorage.setItem('accessToken', payload.token)
    localStorage.setItem('expired_at', payload.expired_at)
    // window.Echo.connector.pusher.config.auth.headers['Authorization'] = `Bearer ${payload.token}`;
  },

  SET_BEARER (state, accessToken) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`
  }
}
