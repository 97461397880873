/*=========================================================================================
  File Name: router.js
  Description: Routes for vue-router. Lazy loading is enabled.
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


import Vue from 'vue'
import Router from 'vue-router'
import {afterEach} from '@/router/afterEach'
import {beforeEach} from '@/router/beforeEach'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior () {
    return {
      x: 0,
      y: 0
    }
  },
  routes: [

    {
      // =============================================================================
      // MAIN LAYOUT ROUTES
      // =============================================================================
      path: '',
      component: () => import('../layouts/main/Main.vue'),
      meta: {requiresAuth: true},
      children: [
        // =============================================================================
        // HOME Routes
        // =============================================================================
        {
          path: '/',
          name: 'home',
          component: () => import(/* webpackChunkName: "home" */ '../views/admin/Home.vue'),
          meta: {
            pageTitle: 'داشبورد',
            pageTitleI18n: 'dashboard'
          }
        },

        // =============================================================================
        // PROFILE PAGES LAYOUTS
        // =============================================================================
        {
          path: '/profile',
          component: () => import('../views/admin/profile/index.vue'),
          children: [
            {
              path: '/',
              component: () => import(/* webpackChunkName: "profile" */ '../views/admin/profile/profile/userProfile.vue'),
              meta: {
                pageTitle: '',
                pageTitleI18n: 'profile.title'
                // rule: 'editor'
              },
              name: 'Profile'
            },
            {
              path: 'transactions',
              name: 'profileTransactions',
              component: () => import(/* webpackChunkName: "profileTransactions" */ '../views/admin/profile/transactions/profileTransactions.vue'),
              meta: {
                pageTitle: '',
                pageTitleI18n: 'profile.transactions.title'
                // rule: 'editor'
              }
            },
            {
              path: 'addresses',
              name: 'profileAddresses',
              component: () => import(/* webpackChunkName: "profileAddresses" */ '../views/admin/profile/addresses/profileAddresses.vue'),
              meta: {
                pageTitle: '',
                pageTitleI18n: 'profile.address.title'
                // rule: 'editor'
              }
            },
            {
              path: 'received-events',
              name: 'profileReceivedEvents',
              component: () => import(/* webpackChunkName: "profileReceivedEvents" */ '../views/admin/profile/events/profileEvents.vue'),
              meta: {
                pageTitle: '',
                pageTitleI18n: 'profile.events.receive.title'
                // rule: 'editor'
              }
            },
            {
              path: 'sent-events',
              name: 'profileSentEvents',
              component: () => import(/* webpackChunkName: "profileSentEvents" */ '../views/admin/profile/events/profileEvents.vue'),
              meta: {
                pageTitle: '',
                pageTitleI18n: 'profile.events.send.title'
                // rule: 'editor'
              }
            },
            {
              path: 'edit',
              name: 'editProfile',
              component: () => import(/* webpackChunkName: "editProfile" */ '../views/admin/profile/edit/editProfile.vue'),
              meta: {
                pageTitle: '',
                pageTitleI18n: 'profile.edit.title'
                // rule: 'editor'
              }
            }
          ]
        },

        // =============================================================================
        // EVENTS PAGES LAYOUTS
        // =============================================================================
        {
          path: '/events',
          component: () => import('../views/admin/events/index.vue'),
          children: [
            {
              path: '/',
              name: 'events',
              component: () => import(/* webpackChunkName: "events" */ '../views/admin/events/list/events.vue'),
              meta: {
                permission: 'event.show',
                pageTitle: '',
                pageTitleI18n: 'events.title'
                // rule: 'editor'
              }
            }
          ]
        },

        // =============================================================================
        // USERS PAGES LAYOUTS
        // =============================================================================
        {
          path: '/users',
          component: () => import('../views/admin/users/index.vue'),
          children: [
            {
              path: '/',
              name: 'users',
              component: () => import(/* webpackChunkName: "users" */ '../views/admin/users/list/usersList.vue'),
              meta: {
                permission: 'user.show',
                pageTitle: 'اشخاص',
                pageTitleI18n: 'users.list.title'
                // rule: 'editor'
              }
            },
            {
              path: 'trash',
              name: 'usersTrash',
              component: () => import(/* webpackChunkName: "usersTrash" */ '../views/admin/users/trash/usersTrash.vue'),
              meta: {
                permission: 'user.delete',
                pageTitle: 'سطل زباله اشخاص',
                pageTitleI18n: 'users.trash.title'
                // rule: 'editor'
              }
            },
            {
              path: 'insert',
              name: 'insertUser',
              component: () => import(/* webpackChunkName: "insertUser" */ '../views/admin/users/insert/insertUser.vue'),
              meta: {
                permission: 'user.create',
                pageTitle: 'افزودن شخص',
                pageTitleI18n: 'users.insert.title'
                // rule: 'editor'
              }
            },
            {
              path: ':id',
              component: () => import('../views/admin/users/user/index.vue'),
              children: [
                {
                  path: '/',
                  component: () => import(/* webpackChunkName: "user" */ '../views/admin/users/user/profile/userProfile'),
                  name: 'user',
                  meta: {
                    permission: 'user.show',
                    pageTitle: 'شخص',
                    pageTitleI18n: 'users.profile.title'
                    // rule: 'editor'
                  }
                },
                {
                  path: 'transactions',
                  name: 'userTransactions',
                  component: () => import(/* webpackChunkName: "userTransactions" */ '../views/admin/users/user/transactions/userTransactions.vue'),
                  meta: {
                    permission: 'user.show',
                    pageTitle: 'گردش مالی شخص',
                    pageTitleI18n: 'users.transactions.title'
                    // rule: 'editor'
                  }
                },
                {
                  path: 'transactions/:docId',
                  name: 'userTransaction',
                  component: () => import(/* webpackChunkName: "userTransaction" */ '../views/admin/accountancy/documents/document/document.vue'),
                  meta: {
                    permission: 'user.show',
                    pageTitle: 'سند گردش مالی شخص',
                    pageTitleI18n: 'users.transactions.documentTitle'
                    // rule: 'editor'
                  }
                },
                {
                  path: 'addresses',
                  name: 'userAddresses',
                  component: () => import(/* webpackChunkName: "userAddresses" */ '../views/admin/users/user/addresses/userAddresses.vue'),
                  meta: {
                    permission: 'user.show',
                    pageTitle: 'شخص',
                    pageTitleI18n: 'users.addresses.title'
                    // rule: 'editor'
                  }
                },
                {
                  path: 'activities-log',
                  name: 'userActivitiesLog',
                  component: () => import(/* webpackChunkName: "userActivitiesLog" */ '../views/admin/users/user/activitiesLog/userActivitiesLog.vue'),
                  meta: {
                    permission: 'user.show',
                    pageTitle: 'لاگ های شخص',
                    pageTitleI18n: 'users.logs.title'
                    // rule: 'editor'
                  }
                }
              ]
            },
            {
              path: ':id/edit',
              name: 'editUser',
              component: () => import(/* webpackChunkName: "editUser" */ '../views/admin/users/edit/editUser.vue'),
              meta: {
                permission: 'user.update',
                pageTitle: ',ویرایش اطلاعات شخص',
                pageTitleI18n: 'users.edit.title'
                // rule: 'editor'
              }
            }
          ]
        },

        // =============================================================================
        // SALES PAGES LAYOUTS
        // =============================================================================
        {
          path: '/sales',
          component: () => import('../views/admin/sales/index.vue'),
          children: [
            {
              path: '/',
              redirect: '/error-404'
            },
            {
              path: 'invoices',
              component: () => import('../views/admin/sales/invoices/index.vue'),
              children: [
                {
                  path: '/',
                  name: 'saleInvoices',
                  component: () => import(/* webpackChunkName: "saleInvoices" */ '../views/admin/sales/invoices/list/saleInvoicesList.vue'),
                  meta: {
                    permission: 'sale_invoice.show',
                    pageTitle: '',
                    pageTitleI18n: 'sales.invoices.list.title'
                    // rule: 'editor'
                  }
                },
                {
                  path: 'trash',
                  name: 'saleInvoiceTrash',
                  component: () => import(/* webpackChunkName: "usersTrash" */ '../views/admin/sales/invoices/trash/saleInvoicesTrash.vue'),
                  meta: {
                    permission: 'sale_invoice.delete',
                    pageTitle: 'سطل زباله فاکتور فروش',
                    pageTitleI18n: 'sales.invoices.trash.title'
                    // rule: 'editor'
                  }
                },
                {
                  path: 'insert',
                  name: 'insertSaleInvoice',
                  component: () => import(/* webpackChunkName: "insertSaleInvoice" */ '../views/admin/sales/invoices/insert/insertSaleInvoice.vue'),
                  meta: {
                    permission: 'sale_invoice.create',
                    pageTitle: '',
                    pageTitleI18n: 'sales.invoices.insert.title'
                    // rule: 'editor'
                  }
                },
                {
                  path: ':id',
                  name: 'saleInvoice',
                  component: () => import(/* webpackChunkName: "saleInvoice" */ '../views/admin/sales/invoices/invoice/saleInvoice.vue'),
                  meta: {
                    permission: 'sale_invoice.show',
                    pageTitle: '',
                    pageTitleI18n: 'sales.invoices.invoice.title'
                    // rule: 'editor'
                  }
                },
                {
                  path: ':id/edit',
                  name: 'editSaleInvoice',
                  component: () => import(/* webpackChunkName: "editSaleInvoice" */ '../views/admin/sales/invoices/edit/editSaleInvoice.vue'),
                  meta: {
                    permission: 'sale_invoice.update',
                    pageTitle: '',
                    pageTitleI18n: 'sales.invoices.edit.title'
                    // rule: 'editor'
                  }
                },
                {
                  path: ':id/return',
                  name: 'insertReturnSaleInvoice',
                  component: () => import(/* webpackChunkName: "insertReturnSaleInvoice" */ '../views/admin/sales/returnInvoices/insert/insertReturnSaleInvoice.vue'),
                  meta: {
                    permission: ['sale_invoice.show', 'sale_invoice.update', 'return_sale_invoice.create'],
                    pageTitle: '',
                    pageTitleI18n: 'sales.returnInvoices.insert.title'
                    // rule: 'editor'
                  }
                }
              ]
            },
            {
              path: 'return-invoices',
              component: () => import('../views/admin/sales/returnInvoices/index.vue'),
              children: [
                {
                  path: '/',
                  name: 'returnSaleInvoices',
                  component: () => import(/* webpackChunkName: "returnSaleInvoices" */ '../views/admin/sales/returnInvoices/list/returnSaleInvoicesList.vue'),
                  meta: {
                    permission: 'return_sale_invoice.show',
                    pageTitle: '',
                    pageTitleI18n: 'sales.returnInvoices.list.title'
                    // rule: 'editor'
                  }
                },
                {
                  path: 'trash',
                  name: 'returnSaleInvoicesTrash',
                  component: () => import(/* webpackChunkName: "returnSaleInvoices" */ '../views/admin/sales/returnInvoices/trash/returnSaleInvoicesTrash.vue'),
                  meta: {
                    permission: 'return_sale_invoice.delete',
                    pageTitle: '',
                    pageTitleI18n: 'sales.returnInvoices.trash.title'
                    // rule: 'editor'
                  }
                },
                {
                  path: ':id',
                  name: 'returnSaleInvoice',
                  component: () => import(/* webpackChunkName: "returnSaleInvoice" */ '../views/admin/sales/returnInvoices/invoice/returnSaleInvoice.vue'),
                  meta: {
                    permission: 'return_sale_invoice.show',
                    pageTitle: '',
                    pageTitleI18n: 'sales.returnInvoices.invoice.title'
                    // rule: 'editor'
                  }
                },
                {
                  path: ':id/edit',
                  name: 'editReturnSaleInvoice',
                  component: () => import(/* webpackChunkName: "editReturnSaleInvoice" */ '../views/admin/sales/returnInvoices/edit/editReturnSaleInvoice.vue'),
                  meta: {
                    permission: 'return_sale_invoice.update',
                    pageTitle: '',
                    pageTitleI18n: 'sales.returnInvoices.edit.title'
                    // rule: 'editor'
                  }
                }
              ]
            },
            {
              path: 'groups',
              component: () => import('../views/admin/sales/groups/index'),
              children: [
                {
                  path: '/',
                  name: 'saleGroupsList',
                  component: () => import(/* webpackChunkName: "saleGroupsList" */ '../views/admin/sales/groups/list/saleGroupsList.vue'),
                  meta: {
                    pageTitle: 'لیست گروه های فروش',
                    pageTitleI18n: 'sales.groups.list.title'
                    // rule: 'editor'
                  }
                },
                {
                  path: 'insert',
                  name: 'insertSaleGroup',
                  component: () => import(/* webpackChunkName: "insertSaleGroup" */ '../views/admin/sales/groups/insert/insertSaleGroup.vue'),
                  meta: {
                    pageTitle: 'افزودن گروه فروش جدید',
                    pageTitleI18n: 'sales.groups.insert.title'
                    // rule: 'editor'
                  }
                },
                {
                  path: 'grp-:id/edit',
                  name: 'editSaleGroup',
                  component: () => import(/* webpackChunkName: "editSaleGroup" */ '../views/admin/sales/groups/edit/editSaleGroup.vue'),
                  meta: {
                    pageTitle: 'ویرایش گروه فروش',
                    pageTitleI18n: 'sales.groups.edit.title'
                    // rule: 'editor'
                  }
                }
              ]
            }
          ]
        },

        // =============================================================================
        // PURCHASE PAGES LAYOUTS
        // =============================================================================
        {
          path: '/purchases',
          component: () => import('../views/admin/purchases/index.vue'),
          children: [
            {
              path: '/',
              redirect: '/error-404'
            },
            {
              path: 'invoices',
              component: () => import('../views/admin/purchases/invoices/index.vue'),
              children: [
                {
                  path: '/',
                  name: 'purchasesInvoices',
                  component: () => import(/* webpackChunkName: "purchasesInvoices" */ '../views/admin/purchases/invoices/list/purchaseInvoicesList.vue'),
                  meta: {
                    permission: 'purchase_invoice.show',
                    pageTitle: 'فاکتورهای خرید',
                    pageTitleI18n: 'purchases.invoices.list.title'
                    // rule: 'editor'
                  }
                },
                {
                  path: 'trash',
                  name: 'purchaseInvoicesTrash',
                  component: () => import(/* webpackChunkName: "purchasesInvoices" */ '../views/admin/purchases/invoices/trash/purchaseInvoicesTrash.vue'),
                  meta: {
                    permission: 'purchase_invoice.delete',
                    pageTitle: 'سطل زباله فاکتور خرید',
                    pageTitleI18n: 'purchases.invoices.trash.title'
                    // rule: 'editor'
                  }
                },
                {
                  path: 'suggest',
                  name: 'suggestPurchaseInvoice',
                  component: () => import(/* webpackChunkName: "suggestPurchasesInvoices" */ '../views/admin/purchases/invoices/suggest/purchaseSuggest.vue'),
                  meta: {
                    permission: 'suggest_purchase_invoice.show',
                    pageTitle: 'پیشنهاد فاکتور خرید',
                    pageTitleI18n: 'purchases.invoices.suggest.title'
                    // rule: 'editor'
                  }
                },
                {
                  path: 'insert',
                  name: 'insertPurchaseInvoice',
                  component: () => import(/* webpackChunkName: "insertPurchaseInvoice" */ '../views/admin/purchases/invoices/insert/insertPurchaseInvoice.vue'),
                  meta: {
                    permission: 'purchase_invoice.create',
                    pageTitle: 'افزودن فاکتور خرید',
                    pageTitleI18n: 'purchases.invoices.insert.title'
                    // rule: 'editor'
                  }
                },
                {
                  path: ':id',
                  name: 'purchaseInvoice',
                  component: () => import(/* webpackChunkName: "purchaseInvoice" */ '../views/admin/purchases/invoices/invoice/purchaseInvoice.vue'),
                  meta: {
                    permission: 'purchase_invoice.show',
                    pageTitle: 'فاکتور خرید',
                    pageTitleI18n: 'purchases.invoices.invoice.title'
                    // rule: 'editor'
                  }
                },
                {
                  path: ':id/edit',
                  name: 'editPurchaseInvoice',
                  component: () => import(/* webpackChunkName: "editPurchaseInvoice" */ '../views/admin/purchases/invoices/edit/editPurchaseInvoice.vue'),
                  meta: {
                    permission: 'purchase_invoice.update',
                    pageTitle: 'ویرایش فاکتور خرید',
                    pageTitleI18n: 'purchases.invoices.edit.title'
                    // rule: 'editor'
                  }
                },
                {
                  path: ':id/return',
                  name: 'insertReturnPurchaseInvoice',
                  component: () => import(/* webpackChunkName: "insertReturnPurchaseInvoice" */ '../views/admin/purchases/returnPurchase/insert/insertReturnPurchaseInvoice.vue'),
                  meta: {
                    permission: ['purchase_invoice.show', 'purchase_invoice.update', 'return_purchase_invoice.create'],
                    pageTitle: 'افزودن فاکتور برگشت از خرید',
                    pageTitleI18n: 'purchases.returnInvoices.insert.title'
                    // rule: 'editor'
                  }
                }
              ]
            },
            {
              path: 'return-invoices',
              component: () => import('../views/admin/purchases/returnPurchase/index.vue'),
              children: [
                {
                  path: '/',
                  name: 'returnPurchaseInvoices',
                  component: () => import(/* webpackChunkName: "returnPurchaseInvoices" */ '../views/admin/purchases/returnPurchase/list/returnPurchaseInvoicesList.vue'),
                  meta: {
                    permission: 'return_purchase_invoice.show',
                    pageTitle: 'فاکتورهای مرجوع خرید',
                    pageTitleI18n: 'purchases.returnInvoices.list.title'
                    // rule: 'editor'
                  }
                },
                {
                  path: 'trash',
                  name: 'returnPurchaseInvoicesTrash',
                  component: () => import(/* webpackChunkName: "purchasesInvoices" */ '../views/admin/purchases/returnPurchase/trash/returnPurchaseInvoicesTrash.vue'),
                  meta: {
                    permission: 'return_purchase_invoice.delete',
                    pageTitle: 'سطل زباله فاکتور مرجوع خرید',
                    pageTitleI18n: 'purchases.returnInvoices.trash.title'
                    // rule: 'editor'
                  }
                },
                {
                  path: ':id',
                  name: 'returnPurchaseInvoice',
                  component: () => import(/* webpackChunkName: "returnPurchaseInvoice" */ '../views/admin/purchases/returnPurchase/invoice/returnPurchaseInvoice.vue'),
                  meta: {
                    permission: 'return_purchase_invoice.show',
                    pageTitle: 'فاکتور مرجوع خرید',
                    pageTitleI18n: 'purchases.returnInvoices.invoice.title'
                    // rule: 'editor'
                  }
                },
                {
                  path: ':id/edit',
                  name: 'editReturnPurchaseInvoice',
                  component: () => import(/* webpackChunkName: "editReturnPurchaseInvoice" */ '../views/admin/purchases/returnPurchase/edit/editReturnPurchaseInvoice.vue'),
                  meta: {
                    permission: 'return_purchase_invoice.update',
                    pageTitle: 'ویرایش فاکتور مرجوع خرید',
                    pageTitleI18n: 'purchases.returnInvoices.edit.title'
                    // rule: 'editor'
                  }
                }
              ]
            }
          ]
        },

        // =============================================================================
        // TREASURY PAGES LAYOUTS
        // =============================================================================
        {
          path: '/treasury',
          component: () => import('../views/admin/treasury/index.vue'),
          children: [
            {
              path: '/',
              redirect: '/error-404'
            },
            {
              path: 'banks',
              component: () => import('../views/admin/treasury/banks/index.vue'),
              children: [
                {
                  path: '/',
                  name: 'treasuryBanks',
                  component: () => import(/* webpackChunkName: "treasuryBanks" */ '../views/admin/treasury/banks/list/banksList.vue'),
                  meta: {
                    permission: 'bank.show',
                    pageTitle: 'بانک ها',
                    pageTitleI18n: 'treasury.banks.title'
                    // rule: 'editor'
                  }
                },
                {
                  path: 'insert',
                  name: 'insertTreasuryBank',
                  component: () => import(/* webpackChunkName: "insertTreasuryBank" */ '../views/admin/treasury/banks/insert/insertBank.vue'),
                  meta: {
                    permission: 'bank.create',
                    pageTitle: 'افزودن بانک',
                    pageTitleI18n: 'treasury.banks.insert.title'
                    // rule: 'editor'
                  }
                },
                {
                  path: 'trash',
                  name: 'treasuryBankTrash',
                  component: () => import(/* webpackChunkName: "treasuryBankTrash" */ '../views/admin/treasury/banks/trash/banksTrash.vue'),
                  meta: {
                    permission: 'bank.delete',
                    pageTitle: 'سطل زباله بانک ها',
                    pageTitleI18n: 'treasury.banks.trash.title'
                    // rule: 'editor'
                  }
                },
                {
                  path: ':id',
                  component: () => import('../views/admin/treasury/banks/bank/index.vue'),
                  children: [
                    {
                      path: '/',
                      name: 'treasuryBank',
                      component: () => import(/* webpackChunkName: "treasuryBank" */ '../views/admin/treasury/banks/bank/menu/bankMenu.vue'),
                      meta: {
                        permission: 'bank.show',
                        pageTitle: 'بانک',
                        pageTitleI18n: 'treasury.banks.bank.title'
                        // rule: 'editor'
                      }
                    },
                    {
                      path: 'edit',
                      name: 'editTreasuryBank',
                      component: () => import(/* webpackChunkName: "editTreasuryBank" */ '../views/admin/treasury/banks/bank/edit/editBank.vue'),
                      meta: {
                        permission: 'bank.update',
                        pageTitle: 'ویرایش بانک',
                        pageTitleI18n: 'treasury.banks.edit.title'
                        // rule: 'editor'
                      }
                    },
                    {
                      path: 'transactions',
                      name: 'transactionsTreasuryBank',
                      component: () => import(/* webpackChunkName: "transactionsTreasuryBank" */ '../views/admin/treasury/banks/bank/transactions/bankTransactions.vue'),
                      meta: {
                        permission: 'bank.show',
                        pageTitle: '',
                        pageTitleI18n: 'treasury.banks.transactions.title'
                        // rule: 'editor'
                      }
                    }
                  ]
                }
              ]
            },
            {
              path: 'payment-gateways',
              component: () => import('../views/admin/treasury/paymentGateways/index.vue'),
              children: [
                {
                  path: '/',
                  name: 'paymentGateways',
                  component: () => import(/* webpackChunkName: "paymentGateways" */ '../views/admin/treasury/paymentGateways/list/paymentGateways.vue'),
                  meta: {
                    permission: 'payment_gateway.show',
                    pageTitle: 'درگاه های پرداخت',
                    pageTitleI18n: 'treasury.paymentGateways.title'
                    // rule: 'editor'
                  }
                },
                {
                  path: 'insert',
                  name: 'insertPaymentGateway',
                  component: () => import(/* webpackChunkName: "insertPaymentGateway" */ '../views/admin/treasury/paymentGateways/insert/insertPaymentGateway.vue'),
                  meta: {
                    permission: 'payment_gateway.create',
                    pageTitle: 'افزودن درگاه پرداخت',
                    pageTitleI18n: 'treasury.paymentGateways.insert.title'
                    // rule: 'editor'
                  }
                },
                {
                  path: 'trash',
                  name: 'trashedPaymentGateways',
                  component: () => import(/* webpackChunkName: "trashedPaymentGateways" */ '../views/admin/treasury/paymentGateways/trash/trashedPaymentGateways.vue'),
                  meta: {
                    permission: 'payment_gateway.delete',
                    pageTitle: 'سطل زباله درگاه های پرداخت',
                    pageTitleI18n: 'treasury.paymentGateways.trash.title'
                    // rule: 'editor'
                  }
                },
                {
                  path: ':id',
                  component: () => import('../views/admin/treasury/paymentGateways/paymentGateway/index.vue'),
                  children: [
                    {
                      path: '/',
                      name: 'paymentGateway',
                      component: () => import(/* webpackChunkName: "paymentGateway" */ '../views/admin/treasury/paymentGateways/paymentGateway/profile/paymentGateway.vue'),
                      meta: {
                        permission: 'payment_gateway.show',
                        pageTitle: 'درگاه پرداخت',
                        pageTitleI18n: 'treasury.paymentGateways.paymentGateway.title'
                        // rule: 'editor'
                      }
                    },
                    {
                      path: 'edit',
                      name: 'editPaymentGateway',
                      component: () => import(/* webpackChunkName: "editPaymentGateway" */ '../views/admin/treasury/paymentGateways/paymentGateway/edit/editPaymentGateway.vue'),
                      meta: {
                        permission: 'payment_gateway.update',
                        pageTitle: 'ویرایش درگاه پرداخت',
                        pageTitleI18n: 'treasury.paymentGateways.edit.title'
                        // rule: 'editor'
                      }
                    },
                    {
                      path: 'transactions',
                      name: 'transactionsPaymentGateway',
                      component: () => import(/* webpackChunkName: "transactionsPaymentGateway" */ '../views/admin/treasury/paymentGateways/paymentGateway/transactions/paymentGatewayTransactions.vue'),
                      meta: {
                        permission: 'payment_gateway.show',
                        pageTitle: 'گردش مالی درگاه پرداخت',
                        pageTitleI18n: 'treasury.paymentGateways.transactions.title'
                        // rule: 'editor'
                      }
                    }
                  ]
                }
              ]
            },
            {
              path: 'cash_boxes',
              component: () => import('../views/admin/treasury/cashBoxes/index.vue'),
              children: [
                {
                  path: '/',
                  name: 'treasuryCashBoxes',
                  component: () => import(/* webpackChunkName: "treasuryCashBoxes" */ '../views/admin/treasury/cashBoxes/list/cashBoxList.vue'),
                  meta: {
                    permission: 'cash.show',
                    pageTitle: 'صندوق ها',
                    pageTitleI18n: 'treasury.cashBoxes.title'
                    // rule: 'editor'
                  }
                },
                {
                  path: 'insert',
                  name: 'insertTreasuryCashBox',
                  component: () => import(/* webpackChunkName: "insertTreasuryCashBox" */ '../views/admin/treasury/cashBoxes/insert/insertCashBox.vue'),
                  meta: {
                    permission: 'cash.create',
                    pageTitle: 'افزودن صندوق',
                    pageTitleI18n: 'treasury.cashBoxes.insert.title'
                    // rule: 'editor'
                  }
                },
                {
                  path: 'trash',
                  name: 'treasuryCashBoxTrash',
                  component: () => import(/* webpackChunkName: "treasuryCashBoxTrash" */ '../views/admin/treasury/cashBoxes/trash/cashBoxesTrash.vue'),
                  meta: {
                    permission: 'cash.delete',
                    pageTitle: 'سطل زباله صندوق ها',
                    pageTitleI18n: 'treasury.cashBoxes.trash.title'
                    // rule: 'editor'
                  }
                },
                {
                  path: ':id',
                  component: () => import('../views/admin/treasury/cashBoxes/cashBox/index.vue'),
                  children: [
                    {
                      path: '/',
                      name: 'treasuryCashBox',
                      component: () => import(/* webpackChunkName: "treasuryCashBox" */ '../views/admin/treasury/cashBoxes/cashBox/menu/cashBoxMenu.vue'),
                      meta: {
                        permission: 'cash.show',
                        pageTitle: 'صندوق',
                        pageTitleI18n: 'treasury.cashBoxes.cashBox.title'
                        // rule: 'editor'
                      }
                    },
                    {
                      path: 'edit',
                      name: 'editTreasuryCashBox',
                      component: () => import(/* webpackChunkName: "editTreasuryCashBox" */ '../views/admin/treasury/cashBoxes/cashBox/edit/editCashBox.vue'),
                      meta: {
                        permission: 'cash.update',
                        pageTitle: 'ویرایش صندوق',
                        pageTitleI18n: 'treasury.cashBoxes.edit.title'
                        // rule: 'editor'
                      }
                    },
                    {
                      path: 'transactions',
                      name: 'transactionsTreasuryCashBox',
                      component: () => import(/* webpackChunkName: "transactionsTreasuryCashBox" */ '../views/admin/treasury/cashBoxes/cashBox/transactions/cashBoxTransactions.vue'),
                      meta: {
                        permission: 'cash.show',
                        pageTitle: 'گزارشات صندوق',
                        pageTitleI18n: 'treasury.cashBoxes.transactions.title'
                        // rule: 'editor'
                      }
                    }
                  ]
                }
              ]
            },
            {
              path: 'receive',
              component: () => import('../views/admin/treasury/receive/index.vue'),
              children: [
                {
                  path: '',
                  name: 'treasuryReceive',
                  component: () => import(/* webpackChunkName: "treasuryReceive" */ '../views/admin/treasury/receive/list/receivesList.vue'),
                  meta: {
                    pageTitle: 'دریافت',
                    pageTitleI18n: 'treasury.receive.list.title'
                    // rule: 'editor'
                  }
                },
                {
                  path: 'insert',
                  name: 'insertTreasuryReceive',
                  component: () => import(/* webpackChunkName: "insertTreasuryReceive" */ '../views/admin/treasury/receive/insert/insertReceive.vue'),
                  meta: {
                    pageTitle: 'دریافت',
                    pageTitleI18n: 'treasury.receive.insert.title'
                    // rule: 'editor'
                  }
                },
                {
                  path: 'doc-:id',
                  name: 'treasuryReceiveDocument',
                  component: () => import(/* webpackChunkName: "treasuryReceiveDocument" */ '../views/admin/treasury/receive/document/receiveDocument.vue'),
                  meta: {
                    pageTitle: 'سند دریافت',
                    pageTitleI18n: 'treasury.receive.document.title'
                    // rule: 'editor'
                  }
                }
              ]
            },
            {
              path: 'cost',
              component: () => import('../views/admin/treasury/cost/index.vue'),
              children: [
                {
                  path: '',
                  name: 'treasuryCost',
                  component: () => import(/* webpackChunkName: "treasuryCost" */ '../views/admin/treasury/cost/list/costsList.vue'),
                  meta: {
                    permission: 'cost.show',
                    pageTitle: 'هزینه',
                    pageTitleI18n: 'treasury.cost.list.title'
                    // rule: 'editor'
                  }
                },
                {
                  path: 'insert',
                  name: 'insertTreasuryCost',
                  component: () => import(/* webpackChunkName: "insertTreasuryCost" */ '../views/admin/treasury/cost/insert/insertCost.vue'),
                  meta: {
                    permission: 'cost.create',
                    pageTitle: 'هزینه جدید',
                    pageTitleI18n: 'treasury.cost.insert.title'
                    // rule: 'editor'
                  }
                },
                {
                  path: 'doc-:id',
                  name: 'treasuryCostDocument',
                  component: () => import(/* webpackChunkName: "treasuryCostDocument" */ '../views/admin/treasury/cost/document/costDocument.vue'),
                  meta: {
                    permission: 'cost.show',
                    pageTitle: 'سند هزینه',
                    pageTitleI18n: 'treasury.cost.document.title'
                    // rule: 'editor'
                  }
                }
              ]
            },
            {
              path: 'payment',
              component: () => import('../views/admin/treasury/payment/index.vue'),
              children: [
                {
                  path: '',
                  name: 'treasuryPayment',
                  component: () => import(/* webpackChunkName: "treasuryPayment" */ '../views/admin/treasury/payment/list/paymentsList.vue'),
                  meta: {
                    pageTitle: 'پرداخت',
                    pageTitleI18n: 'treasury.payment.list.title'
                    // rule: 'editor'
                  }
                },
                {
                  path: 'insert',
                  name: 'insertTreasuryPayment',
                  component: () => import(/* webpackChunkName: "insertTreasuryPayment" */ '../views/admin/treasury/payment/insert/insertPayment.vue'),
                  meta: {
                    pageTitle: 'پرداخت جدید',
                    pageTitleI18n: 'treasury.payment.insert.title'
                    // rule: 'editor'
                  }
                },
                {
                  path: 'doc-:id',
                  name: 'treasuryPaymentDocument',
                  component: () => import(/* webpackChunkName: "treasuryPaymentDocument" */ '../views/admin/treasury/payment/document/paymentDocument.vue'),
                  meta: {
                    pageTitle: 'سند پرداخت',
                    pageTitleI18n: 'treasury.payment.document.title'
                    // rule: 'editor'
                  }
                }
              ]
            },
            {
              path: 'internal-funds-transfers',
              component: () => import('../views/admin/treasury/InternalFundsTransfer/index.vue'),
              children: [
                {
                  path: '',
                  name: 'treasuryInternalFundsTransfers',
                  component: () => import(/* webpackChunkName: "treasuryInternalFundsTransfers" */ '../views/admin/treasury/InternalFundsTransfer/list/internalFundsTransfersList.vue'),
                  meta: {
                    pageTitle: 'انتقالی خزانه',
                    pageTitleI18n: 'treasury.internalFundsTransfer.title'
                    // rule: 'editor'
                  }
                },
                {
                  path: 'doc-:id',
                  name: 'treasuryInternalFundsTransferDocument',
                  component: () => import(/* webpackChunkName: "treasuryInternalFundsTransferDocument" */ '../views/admin/treasury/InternalFundsTransfer/document/internalFundsTransferDocument.vue'),
                  meta: {
                    pageTitle: 'انتقالی خزانه',
                    pageTitleI18n: 'treasury.internalFundsTransfer.document.title'
                    // rule: 'editor'
                  }
                },
                {
                  path: 'insert',
                  name: 'insertTreasuryInternalFundsTransfers',
                  component: () => import(/* webpackChunkName: "insertTreasuryInternalFundsTransfers" */ '../views/admin/treasury/InternalFundsTransfer/insert/insertInternalFundsTransfer.vue'),
                  meta: {
                    pageTitle: 'انتقالی خزانه جدید',
                    pageTitleI18n: 'treasury.internalFundsTransfer.insert.title'
                    // rule: 'editor'
                  }
                }
              ]
            }
          ]
        },

        // =============================================================================
        // PRODUCTS PAGES LAYOUTS
        // =============================================================================
        {
          path: '/products',
          component: () => import('../views/admin/products/index'),
          children: [
            {
              path: '',
              component: () => import('../views/admin/products/productsList/index'),
              children: [
                {
                  path: '',
                  name: 'ProductsList',
                  component: () => import(/* webpackChunkName: "ProductsList" */ '../views/admin/products/productsList/list/productsList.vue'),
                  meta: {
                    pageTitle: 'لیست کالا و خدمت',
                    pageTitleI18n: 'products.list.title'
                    // rule: 'editor'
                  }
                },
                {
                  path: 'trash',
                  name: 'ProductsTrash',
                  component: () => import(/* webpackChunkName: "ProductsTrash" */ '../views/admin/products/productsList/trash/productsTrash.vue'),
                  meta: {
                    pageTitle: 'سطل آشغال کالا و خدمت',
                    pageTitleI18n: 'products.trash.title'
                    // rule: 'editor'
                  }
                },
                {
                  path: 'prd-:id',
                  component: () => import('../views/admin/products/productsList/product/index.vue'),
                  children: [
                    {
                      path: '/',
                      name: 'Product',
                      component: () => import(/* webpackChunkName: "Product" */ '../views/admin/products/productsList/product/menu/productMenu.vue'),
                      meta: {
                        pageTitle: 'کالا',
                        pageTitleI18n: 'products.product.title'
                        // rule: 'editor'
                      }
                    },
                    {
                      path: 'attributes',
                      name: 'ProductAttributes',
                      component: () => import(/* webpackChunkName: "ProductAttributes" */ '../views/admin/products/productsList/product/attributes/productAttributes.vue'),
                      meta: {
                        pageTitle: 'ویژگی های کالا',
                        pageTitleI18n: 'products.product.attributes.title'
                        // rule: 'editor'
                      }
                    },
                    {
                      path: 'turnover',
                      name: 'ProductTurnover',
                      component: () => import(/* webpackChunkName: "ProductTurnover" */ '../views/admin/products/productsList/product/turnover/productSaleTurnover.vue'),
                      meta: {
                        pageTitle: 'گردش انبار کالا',
                        pageTitleI18n: 'products.product.turnover.sale.title'
                        // rule: 'editor'
                      }
                    },
                    {
                      path: 'variants',
                      name: 'ProductVariants',
                      component: () => import(/* webpackChunkName: "ProductVariants" */ '../views/admin/products/productsList/product/variants/productVariants.vue'),
                      meta: {
                        pageTitle: 'متغیر های کالا',
                        pageTitleI18n: 'products.product.variants.title'
                        // rule: 'editor'
                      }
                    },
                    {
                      path: 'edit',
                      name: 'EditProduct',
                      component: () => import(/* webpackChunkName: "EditProduct" */ '../views/admin/products/productsList/edit/editProduct.vue'),
                      meta: {
                        pageTitle: 'ویرایش کالا',
                        pageTitleI18n: 'products.edit.title'
                        // rule: 'editor'
                      }
                    }
                  ]
                },
                {
                  path: 'insert',
                  name: 'InsertProduct',
                  component: () => import(/* webpackChunkName: "InsertProduct" */ '../views/admin/products/productsList/insert/insertProduct.vue'),
                  meta: {
                    pageTitle: 'افزودن کالا',
                    pageTitleI18n: 'products.insert.title'
                    // rule: 'editor'
                  }
                }
              ]
            },
            {
              path: 'pricing',
              component: () => import(/* webpackChunkName: "pricing" */ '../views/admin/products/productPricing/productPricing.vue'),
              name: 'productsPricing',
              meta: {
                pageTitle: 'قیمت گذاری محصولات',
                pageTitleI18n: 'products.pricing.title'
                // rule: 'editor'
              }
            },
            {
              path: 'categories',
              component: () => import(/* webpackChunkName: "categories" */ '../views/admin/products/categories/categories.vue'),
              name: 'categories',
              meta: {
                pageTitle: 'لیست دسته بندی ها',
                pageTitleI18n: 'categories.list.title'
                // rule: 'editor'
              }
            },
            {
              path: 'attributes',
              component: () => import(/* webpackChunkName: "attributes" */ '../views/admin/products/Attributes/list/AttributesList.vue'),
              name: 'attributes',
              meta: {
                pageTitle: 'لیست ویژگی ها',
                pageTitleI18n: 'attributes.list.title'
                // rule: 'editor'
              }
            }
          ]
        },

        // =============================================================================
        // SERVICE PAGES LAYOUTS
        // =============================================================================
        {
          path: '/services',
          component: () => import('../views/admin/services/index'),
          children: [
            {
              path: '',
              component: () => import('../views/admin/services/servicesList/index'),
              children: [
                {
                  path: '',
                  name: 'ServicesList',
                  component: () => import(/* webpackChunkName: "ServicesList" */ '../views/admin/services/servicesList/list/servicesList.vue'),
                  meta: {
                    pageTitle: 'لیست خدمت',
                    pageTitleI18n: 'services.list.title'
                    // rule: 'editor'
                  }
                },
                {
                  path: 'trash',
                  name: 'ServicesTrash',
                  component: () => import(/* webpackChunkName: "ServicesTrash" */ '../views/admin/services/servicesList/trash/servicesTrash.vue'),
                  meta: {
                    pageTitle: 'سطل آشغال خدمت',
                    pageTitleI18n: 'services.trash.title'
                    // rule: 'editor'
                  }
                },
                {
                  path: 'service-:id',
                  component: () => import('../views/admin/services/servicesList/service/index.vue'),
                  children: [
                    {
                      path: '/',
                      name: 'Service',
                      component: () => import(/* webpackChunkName: "Service" */ '../views/admin/services/servicesList/service/menu/serviceMenu.vue'),
                      meta: {
                        pageTitle: 'خدمت',
                        pageTitleI18n: 'services.service.title'
                        // rule: 'editor'
                      }
                    },
                    // {
                    //   path: 'attributes',
                    //   name: 'ProductAttributes',
                    //   component: () => import(/* webpackChunkName: "ProductAttributes" */ '../views/admin/services/servicesList/service/attributes/productAttributes.vue'),
                    //   meta: {
                    //     pageTitle: 'ویژگی های خدمت',
                    //     pageTitleI18n: 'services.service.attributes.title'
                    //     // rule: 'editor'
                    //   }
                    // },
                    // {
                    //   path: 'turnover',
                    //   name: 'ProductTurnover',
                    //   component: () => import(/* webpackChunkName: "ProductTurnover" */ '../views/admin/products/productsList/product/turnover/productSaleTurnover.vue'),
                    //   meta: {
                    //     pageTitle: 'گردش انبار کالا',
                    //     pageTitleI18n: 'products.product.turnover.sale.title'
                    //     // rule: 'editor'
                    //   }
                    // },
                    // {
                    //   path: 'variants',
                    //   name: 'ProductVariants',
                    //   component: () => import(/* webpackChunkName: "ProductVariants" */ '../views/admin/products/productsList/product/variants/productVariants.vue'),
                    //   meta: {
                    //     pageTitle: 'متغیر های کالا',
                    //     pageTitleI18n: 'products.product.variants.title'
                    //     // rule: 'editor'
                    //   }
                    // },
                    // {
                    //   path: 'edit',
                    //   name: 'EditProduct',
                    //   component: () => import(/* webpackChunkName: "EditProduct" */ '../views/admin/products/productsList/edit/editProduct.vue'),
                    //   meta: {
                    //     pageTitle: 'ویرایش کالا',
                    //     pageTitleI18n: 'products.edit.title'
                    //     // rule: 'editor'
                    //   }
                    // }
                  ]
                },
                {
                  path: 'insert',
                  name: 'InsertService',
                  component: () => import(/* webpackChunkName: "InsertService" */ '../views/admin/services/servicesList/insert/insertService.vue'),
                  meta: {
                    pageTitle: 'افزودن خدمت',
                    pageTitleI18n: 'services.insert.title'
                    // rule: 'editor'
                  }
                }
              ]
            },
            // {
            //   path: 'pricing',
            //   component: () => import('../views/admin/products/productPricing/productPricing.vue'),
            //   name: 'productsPricing',
            //   meta: {
            //     pageTitle: 'قیمت گذاری محصولات',
            //     pageTitleI18n: 'products.pricing.title'
            //     // rule: 'editor'
            //   }
            // },
            // {
            //   path: 'categories',
            //   component: () => import(/* webpackChunkName: "categories" */ '../views/admin/products/categories/categories.vue'),
            //   name: 'categories',
            //   meta: {
            //     pageTitle: 'لیست دسته بندی ها',
            //     pageTitleI18n: 'categories.list.title'
            //     // rule: 'editor'
            //   }
            // },
            // {
            //   path: 'attributes',
            //   component: () => import(/* webpackChunkName: "attributes" */ '../views/admin/products/Attributes/list/AttributesList.vue'),
            //   name: 'attributes',
            //   meta: {
            //     pageTitle: 'لیست ویژگی ها',
            //     pageTitleI18n: 'attributes.list.title'
            //     // rule: 'editor'
            //   }
            // }
          ]
        },

        // =============================================================================
        // STORES PAGES LAYOUTS
        // =============================================================================
        {
          path: '/stores',
          component: () => import('../views/admin/stores/storesList/index'),
          children: [
            {
              path: '',
              name: 'storesList',
              component: () => import(/* webpackChunkName: "storesList" */ '../views/admin/stores/storesList/list/storesList.vue'),
              meta: {
                permission: 'storeroom.show',
                pageTitle: '',
                pageTitleI18n: 'warehouse.stores.list.title'
                // rule: 'editor'
              }
            },
            {
              path: 'trash',
              name: 'storesTrash',
              component: () => import(/* webpackChunkName: "storesTrash" */ '../views/admin/stores/storesList/trash/storesTrash.vue'),
              meta: {
                permission: ['storeroom.show', 'storeroom.forceDelete', 'storeroom.restore'],
                pageTitle: '',
                pageTitleI18n: 'warehouse.stores.trash.title'
                // rule: 'editor'
              }
            },
            {
              path: 'str-:id',
              component: () => import('../views/admin/stores/storesList/store/index'),
              children: [
                {
                  path: '/',
                  name: 'store',
                  component: () => import(/* webpackChunkName: "store" */ '../views/admin/stores/storesList/store/menu/storeMenu.vue'),
                  meta: {
                    permission: 'storeroom.show',
                    pageTitle: '',
                    pageTitleI18n: 'warehouse.stores.store.title'
                    // rule: 'editor'
                  }
                },
                {
                  path: 'edit',
                  name: 'editStore',
                  component: () => import(/* webpackChunkName: "editStore" */ '../views/admin/stores/storesList/store/edit/editStore.vue'),
                  meta: {
                    permission: 'storeroom.show',
                    pageTitle: '',
                    pageTitleI18n: 'warehouse.stores.edit.title'
                    // rule: 'editor'
                  }
                },
                {
                  path: 'turnover',
                  name: 'storeTurnover',
                  component: () => import(/* webpackChunkName: "storeTurnover" */ '../views/admin/stores/storesList/store/turnover/storeTurnover.vue'),
                  meta: {
                    permission: 'storeroom.show',
                    pageTitle: '',
                    pageTitleI18n: 'warehouse.turnover.title'
                    // rule: 'editor'
                  }
                },
                {
                  path: 'inventory',
                  name: 'storeInventory',
                  component: () => import(/* webpackChunkName: "storeInventory" */ '../views/admin/stores/inventory/storesInventory.vue'),
                  meta: {
                    permission: 'storeroom.show',
                    pageTitle: '',
                    pageTitleI18n: 'warehouse.inventory.title'
                    // rule: 'editor'
                  }
                },
                {
                  path: 'inventory-valuation',
                  name: 'storeInventoryValuation',
                  component: () => import(/* webpackChunkName: "storeInventoryValuation" */ '../views/admin/stores/inventoryValuation/storesInventoryValuation.vue'),
                  meta: {
                    permission: 'storeroom.show',
                    pageTitle: '',
                    pageTitleI18n: 'warehouse.inventoryValuation.title'
                    // rule: 'editor'
                  }
                }
              ]
            },
            {
              path: 'insert',
              name: 'InsertStore',
              component: () => import(/* webpackChunkName: "InsertStore" */ '../views/admin/stores/storesList/insert/insertStore.vue'),
              meta: {
                permission: 'storeroom.create',
                pageTitleI18n: 'warehouse.stores.insert.title'
                // rule: 'editor'
              }
            },
            {
              path: 'inventory',
              name: 'storesInventory',
              component: () => import(/* webpackChunkName: "storesInventory" */ '../views/admin/stores/inventory/storesInventory.vue'),
              meta: {
                permission: 'storeroom.inventory',
                pageTitle: '',
                pageTitleI18n: 'warehouse.inventory.title'
                // rule: 'editor'
              }
            },
            {
              path: 'inventory-valuation',
              name: 'storesInventoryValuation',
              component: () => import(/* webpackChunkName: "storesInventoryValuation" */ '../views/admin/stores/inventoryValuation/storesInventoryValuation.vue'),
              meta: {
                permission: 'storeroom.inventoryValuation',
                pageTitle: '',
                pageTitleI18n: 'warehouse.inventoryValuation.title'
                // rule: 'editor'
              }
            },
            {
              path: 'receipts',
              component: () => import(/* webpackChunkName: "receipts" */ '../views/admin/stores/receipts/index'),
              children: [
                {
                  path: '',
                  name: 'storeReceipts',
                  component: () => import(/* webpackChunkName: "storeReceipts" */ '../views/admin/stores/receipts/list/storeReceiptsList'),
                  meta: {
                    permission: 'storeroom_receipt.show',
                    pageTitle: '',
                    pageTitleI18n: 'warehouse.receipts.list.title'
                    // rule: 'editor'
                  }
                },
                {
                  path: 'insert',
                  name: 'insertStoreReceipt',
                  component: () => import(/* webpackChunkName: "insertStoreReceipt" */ '../views/admin/stores/receipts/insert/insertStoreReceipt'),
                  meta: {
                    permission: 'storeroom_receipt.create',
                    pageTitle: '',
                    pageTitleI18n: 'warehouse.receipts.insert.title'
                    // rule: 'editor'
                  }
                },
                {
                  path: ':id',
                  name: 'storeReceipt',
                  component: () => import(/* webpackChunkName: "storeReceipt" */ '../views/admin/stores/receipts/receipt/storeReceipt'),
                  meta: {
                    permission: 'storeroom_receipt.show',
                    pageTitle: '',
                    pageTitleI18n: 'warehouse.receipts.receipt.title'
                    // rule: 'editor'
                  }
                }
              ]
            }
          ]
        },

        // =============================================================================
        // ACCOUNTANCY PAGES LAYOUTS
        // =============================================================================
        {
          path: '/accountancy',
          component: () => import('../views/admin/accountancy/index.vue'),
          children: [
            {
              path: 'types',
              name: 'documentsType',
              component: () => import(/* webpackChunkName: "documentsType" */ '../views/admin/accountancy/types/documentsType.vue'),
              meta: {
                permission: 'account.access',
                pageTitle: '',
                pageTitleI18n: 'accountancy.types.title'
                // rule: 'editor'
              }
            },
            {
              path: 'documents',
              component: () => import('../views/admin/accountancy/documents/index.vue'),
              children: [
                {
                  path: '',
                  name: 'documents',
                  component: () => import(/* webpackChunkName: "documents" */ '../views/admin/accountancy/documents/list/documentsList.vue'),
                  meta: {
                    permission: 'document.access',
                    pageTitle: '',
                    pageTitleI18n: 'accountancy.documents.title'
                    // rule: 'editor'
                  }
                },
                {
                  path: 'insert',
                  name: 'insertDocument',
                  component: () => import(/* webpackChunkName: "insertDocument" */ '../views/admin/accountancy/documents/insert/insertDocument.vue'),
                  meta: {
                    permission: 'document.access',
                    pageTitle: 'افزودن سند دریافت/پرداخت',
                    pageTitleI18n: 'accountancy.documents.insert.dynamicTitle'
                    // rule: 'editor'
                  }
                },
                {
                  path: 'doc-:id',
                  name: 'document',
                  component: () => import(/* webpackChunkName: "document" */ '../views/admin/accountancy/documents/document/document.vue'),
                  meta: {
                    permission: 'document.access',
                    pageTitle: '',
                    pageTitleI18n: 'accountancy.documents.document.title'
                    // rule: 'editor'
                  }
                }
              ]
            },
            {
              path: 'opening-balance',
              name: 'openingBalance',
              component: () => import(/* webpackChunkName: "openingBalance" */ '../views/admin/accountancy/openingBalance/index.vue'),
              meta: {
                permission: 'opening.access',
                pageTitle: '',
                pageTitleI18n: 'accountancy.openingBalance.title'
                // rule: 'editor'
              }
            }
          ]
        },

        // =============================================================================
        // COMPANY PAGES LAYOUTS
        // =============================================================================
        {
          path: 'companies',
          component: () => import('../views/admin/companies/index.vue'),
          children: [
            {
              path: '/',
              name: 'companies',
              component: () => import(/* webpackChunkName: "companies" */ '../views/admin/companies/list/companiesList.vue'),
              meta: {
                pageTitle: 'لیست شرکت ها',
                pageTitleI18n: 'companies.title'
                // rule: 'editor'
              }
            },
            {
              path: 'insert',
              name: 'insertCompany',
              component: () => import(/* webpackChunkName: "insertCompany" */ '../views/admin/companies/insert/insertCompany.vue'),
              meta: {
                pageTitle: '',
                pageTitleI18n: 'companies.insert.title'
                // rule: 'editor'
              }
            },
            {
              path: ':id',
              name: 'company',
              component: () => import(/* webpackChunkName: "company" */ '../views/admin/companies/company/company.vue'),
              meta: {
                pageTitle: 'شرکت',
                pageTitleI18n: 'companies.company.title'
                // rule: 'editor'
              }
            },
            {
              path: ':id/edit',
              name: 'editCompany',
              component: () => import(/* webpackChunkName: "editCompany" */ '../views/admin/companies/edit/editCompany.vue'),
              meta: {
                pageTitle: '',
                pageTitleI18n: 'companies.edit.title'
                // rule: 'editor'
              }
            }
          ]
        },

        // =============================================================================
        // BACKUP PAGES LAYOUTS
        // =============================================================================
        {
          path: 'backup',
          name: 'backup',
          component: () => import(/* webpackChunkName: "backup" */ '../views/admin/backup/backup.vue'),
          meta: {
            pageTitle: '',
            pageTitleI18n: 'backup.title'
            // rule: 'editor'
          }
        },

        // =============================================================================
        // PLUGINS PAGES LAYOUTS
        // =============================================================================
        {
          path: 'wooreceiver',
          component: () => import('../views/admin/plugins/wooreciever/index.vue'),
          children: [
            {
              path: '',
              name: 'wooreceiverList',
              component: () => import(/* webpackChunkName: "wooreceiverList" */ '../views/admin/plugins/wooreciever/list/wooreceiver.vue'),
              meta: {
                permission: 'woocontroller.access',
                pageTitle: '',
                pageTitleI18n: 'plugins.wooreceiver.title'
                // rule: 'editor'
              }
            },
            {
              path: ':id',
              name: 'wooreceiverProfile',
              component: () => import(/* webpackChunkName: "wooreceiverProfile" */ '../views/admin/plugins/wooreciever/profile/wooreceiverProfile.vue'),
              meta: {
                permission: 'woocontroller.access',
                pageTitle: '',
                pageTitleI18n: 'plugins.wooreceiver.profile.title'
                // rule: 'editor'
              }
            },
            {
              path: ':id/settings',
              name: 'wooreceiverSettings',
              component: () => import(/* webpackChunkName: "wooreceiverSettings" */ '../views/admin/plugins/wooreciever/settings/wooreceiverSettings.vue'),
              meta: {
                permission: 'woocontroller.access',
                pageTitle: 'تنظیمات اتصال ساز',
                pageTitleI18n: 'plugins.wooreceiver.setting.title'
                // rule: 'editor'
              }
            },
            {
              path: ':id/attributes',
              name: 'wooreceiverAttributes',
              component: () => import(/* webpackChunkName: "wooreceiverAttributes" */ '../views/admin/plugins/wooreciever/attributes/wooreceiverAttributesList.vue'),
              meta: {
                permission: 'woocontroller.access',
                pageTitle: '',
                pageTitleI18n: 'plugins.wooreceiver.attributes.list.title'
                // rule: 'editor'
              }
            },
            {
              path: ':id/users',
              name: 'wooreceiverUsers',
              component: () => import(/* webpackChunkName: "wooreceiverUsers" */ '../views/admin/plugins/wooreciever/users/wooreceiverUsers.vue'),
              meta: {
                permission: 'woocontroller.access',
                pageTitle: '',
                pageTitleI18n: 'plugins.wooreceiver.users.title'
                // rule: 'editor'
              }
            },
            {
              path: ':id/products',
              name: 'wooreceiverProducts',
              component: () => import(/* webpackChunkName: "wooreceiverProducts" */ '../views/admin/plugins/wooreciever/products/wooreceiverProducts.vue'),
              meta: {
                permission: 'woocontroller.access',
                pageTitle: '',
                pageTitleI18n: 'plugins.wooreceiver.products.title'
                // rule: 'editor'
              }
            },
            {
              path: ':id/products-inventory',
              name: 'wooreceiverProductsInventory',
              component: () => import(/* webpackChunkName: "wooreceiverProductsInventory" */ '../views/admin/plugins/wooreciever/productsInventory/wooreceiverProductsInventory.vue'),
              meta: {
                permission: 'woocontroller.access',
                pageTitle: '',
                pageTitleI18n: 'plugins.wooreceiver.inventory.title'
                // rule: 'editor'
              }
            },
            {
              path: ':id/products-prices',
              name: 'wooreceiverProductsPrices',
              component: () => import(/* webpackChunkName: "wooreceiverProductsPrices" */ '../views/admin/plugins/wooreciever/productsPrices/wooreceiverProductsPrices.vue'),
              meta: {
                permission: 'woocontroller.access',
                pageTitle: '',
                pageTitleI18n: 'plugins.wooreceiver.prices.title'
                // rule: 'editor'
              }
            },
            {
              path: ':id/payment-gateways',
              name: 'wooreceiverPaymentGateways',
              component: () => import(/* webpackChunkName: "wooreceiverPaymentGateways" */ '../views/admin/plugins/wooreciever/paymentGateways/paymentGateways.vue'),
              meta: {
                permission: 'woocontroller.access',
                pageTitle: '',
                pageTitleI18n: 'plugins.wooreceiver.paymentGateways.title'
                // rule: 'editor'
              }
            },
            {
              path: ':id/orders',
              name: 'wooreceiverOrders',
              component: () => import(/* webpackChunkName: "wooreceiverOrders" */ '../views/admin/plugins/wooreciever/orders/wooreceiverOrders.vue'),
              meta: {
                permission: 'woocontroller.access',
                pageTitle: '',
                pageTitleI18n: 'plugins.wooreceiver.orders.title'
                // rule: 'editor'
              }
            },
            {
              path: ':id/categories',
              name: 'wooreceiverCategories',
              component: () => import(/* webpackChunkName: "wooreceiverCategories" */ '../views/admin/plugins/wooreciever/categories/wooreceiverCategories.vue'),
              meta: {
                permission: 'woocontroller.access',
                pageTitle: '',
                pageTitleI18n: 'plugins.wooreceiver.categories.title'
                // rule: 'editor'
              }
            },
            {
              path: ':id/categories-mapper',
              name: 'wooreceiverCategoriesMapper',
              component: () => import(/* webpackChunkName: "wooreceiverCategoriesMapper" */ '../views/admin/plugins/wooreciever/categories/wooreceiverCategoriesMapper.vue'),
              meta: {
                permission: 'woocontroller.access',
                pageTitle: '',
                pageTitleI18n: 'plugins.wooreceiver.categories.categoryMapper.title'
                // rule: 'editor'
              }
            }
          ]
        },
        /*]
      },*/

        // =============================================================================
        // TICKETS PAGES LAYOUTS
        // =============================================================================
        {
          path: '/tickets',
          component: () => import('../views/admin/tickets/index.vue'),
          children: [
            {
              path: 'organization',
              component: () => import('../views/admin/tickets/organizationTicket/index.vue'),
              children: [
                {
                  path: '/',
                  name: 'organizationTickets',
                  component: () => import(/* webpackChunkName: "organizationTickets" */ '../views/admin/tickets/organizationTicket/list/ticketsOrganizationList.vue'),
                  meta: {
                    permission: 'organizational_ticket.show',
                    pageTitle: 'تیکت ها',
                    pageTitleI18n: 'tickets.organization.list.title'
                    // rule: 'editor'
                  }
                },
                {
                  path: 'insert',
                  name: 'insertOrganizationTicket',
                  component: () => import(/* webpackChunkName: "insertOrganizationTicket" */ '../views/admin/tickets/organizationTicket/insert/insertOrganizationTicket.vue'),
                  meta: {
                    permission: 'organizational_ticket.create',
                    pageTitle: 'افزودن تیکت جدید',
                    pageTitleI18n: 'tickets.organization.insert.title'
                    // rule: 'editor'
                  }
                },
                {
                  path: ':id',
                  name: 'organizationTicketDetail',
                  component: () => import(/* webpackChunkName: "organizationTicketDetail" */ '../views/admin/tickets/organizationTicket/profile/ticketOrganizationDetail.vue'),
                  meta: {
                    permission: 'organizational_ticket.show',
                    pageTitle: '',
                    pageTitleI18n: 'tickets.organization.detail.title'
                    // rule: 'editor'
                  }
                }
              ]
            }
          ]
        },

        // =============================================================================
        // SETTING PAGES LAYOUTS
        // =============================================================================
        {
          path: '/settings',
          component: () => import('../views/admin/settings/index.vue'),
          children: [
            {
              path: '/',
              name: 'Settings',
              component: () => import(/* webpackChunkName: "Settings" */ '../views/admin/settings/setting/settingMenu.vue'),
              meta: {
                pageTitle: 'تنظیمات',
                pageTitleI18n: 'setting.title'
                // rule: 'editor'
              }
            },
            {
              path: 'company-info',
              name: 'companyInfoSetting',
              component: () => import(/* webpackChunkName: "companyInfoSetting" */ '../views/admin/settings/companyInfo/companyInfoSetting.vue'),
              meta: {
                pageTitle: '',
                pageTitleI18n: 'setting.companyInfo.title'
                // rule: 'editor'
              }
            },
            {
              path: 'access',
              component: () => import(/* webpackChunkName: "access" */ '../views/admin/settings/access/index.vue'),
              children: [
                {
                  path: '/',
                  name: 'accessGroupsList',
                  component: () => import(/* webpackChunkName: "accessGroupsList" */ '../views/admin/settings/access/list/accessGroupsList.vue'),
                  meta: {
                    permission: 'role.show',
                    pageTitle: '',
                    pageTitleI18n: 'setting.accessGroup.title'
                    // rule: 'editor'
                  }
                }
              ]
            },
            {
              path: 'buy',
              name: 'buySetting',
              component: () => import(/* webpackChunkName: "buySetting" */ '../views/admin/settings/purchase/PurchaseSettings.vue'),
              meta: {
                pageTitle: 'تنظیمات خرید',
                pageTitleI18n: 'setting.purchase.title'
                // rule: 'editor'
              }
            },
            {
              path: 'sale',
              name: 'saleSetting',
              component: () => import(/* webpackChunkName: "saleSetting" */ '../views/admin/settings/sale/SaleSettings.vue'),
              meta: {
                pageTitle: 'تنظیمات فروش',
                pageTitleI18n: 'setting.Sale.title'
                // rule: 'editor'
              }
            },
            {
              path: 'languages',
              name: 'languagesSetting',
              component: () => import(/* webpackChunkName: "languagesSetting" */ '../views/admin/settings/locale/LocaleSetting.vue'),
              meta: {
                pageTitle: '',
                pageTitleI18n: 'setting.Locale.title'
                // rule: 'editor'
              }
            },
            {
              path: 'users',
              name: 'usersSetting',
              component: () => import(/* webpackChunkName: "usersSetting" */ '../views/admin/settings/users/usersSetting.vue'),
              meta: {
                pageTitle: '',
                pageTitleI18n: 'setting.users.title'
                // rule: 'editor'
              }
            },
            {
              path: 'security',
              name: 'securitySetting',
              component: () => import(/* webpackChunkName: "securitySetting" */ '../views/admin/settings/security/securitySetting.vue'),
              meta: {
                pageTitle: '',
                pageTitleI18n: 'setting.security.title'
                // rule: 'editor'
              }
            },
            {
              path: 'customizing',
              name: 'customizeSetting',
              component: () => import(/* webpackChunkName: "customizeSetting" */ '../views/admin/settings/customize/customizeSetting.vue'),
              meta: {
                pageTitle: '',
                pageTitleI18n: 'setting.customize.title'
                // rule: 'editor'
              }
            },
            {
              path: 'financial',
              name: 'financialSetting',
              component: () => import(/* webpackChunkName: "financialSetting" */ '../views/admin/settings/financial/FinancialSetting.vue'),
              meta: {
                pageTitle: '',
                pageTitleI18n: 'setting.financial.title'
                // rule: 'editor'
              }
            },
            {
              path: 'store',
              name: 'storeSetting',
              component: () => import(/* webpackChunkName: "storeSetting" */ '../views/admin/settings/store/StoreSettings.vue'),
              meta: {
                pageTitle: 'تنظیمات انبار',
                pageTitleI18n: 'setting.Store.title'
                // rule: 'editor'
              }
            },
            {
              path: 'financial-year',
              name: 'financialYearSetting',
              component: () => import(/* webpackChunkName: "financialYearSetting" */ '../views/admin/settings/financialYear/list/financialYearList.vue'),
              meta: {
                permission: 'financial_year.show',
                pageTitle: 'تنظیمات سال مالی',
                pageTitleI18n: 'setting.financialYear.title'
                // rule: 'editor'
              }
            },
            {
              path: 'advanced',
              name: 'advancedSetting',
              component: () => import(/* webpackChunkName: "advancedSetting" */ '../views/admin/settings/advanced/advancedSetting.vue'),
              meta: {
                pageTitle: 'تنظیمات پیشرفته',
                pageTitleI18n: ''
                // rule: 'editor'
              }
            }
          ]
        },

        // =============================================================================
        // REPORTS PAGES LAYOUTS
        // =============================================================================
        {
          path: '/reports',
          component: () => import('../views/admin/reports/index'),
          children: [
            {
              path: 'activities-log',
              name: 'activitiesLog',
              component: () => import(/* webpackChunkName: "activitiesLog" */ '../views/admin/reports/activitiesLog/usersActivitiesLog.vue'),
              meta: {
                permission: 'log.show',
                pageTitle: '',
                pageTitleI18n: 'reports.logs.list.title'
                // rule: 'editor'
              }
            },
            {
              path: 'users-total-sales',
              name: 'usersTotalSalesReport',
              component: () => import(/* webpackChunkName: "usersTotalSalesReport" */ '../views/admin/reports/usersTotalSales/usersTotalSalesReport.vue'),
              meta: {
                pageTitle: '',
                pageTitleI18n: 'reports.usersTotalSales.title'
                // rule: 'editor'
              }
            }
          ]
        }
      ]
    },
    // =============================================================================
    // FULL PAGE LAYOUTS
    // =============================================================================
    {
      path: '',
      component: () => import('@/layouts/full-page/FullPage.vue'),
      children: [
        // =============================================================================
        // PAGES
        // =============================================================================
        {
          path: '/login',
          name: 'page-login',
          component: () => import(/* webpackChunkName: "page-login" */ '@/views/pages/Login.vue')
        },
        {
          path: '/error-404',
          name: 'page-error-404',
          component: () => import(/* webpackChunkName: "page-error-404" */ '@/views/pages/Error404.vue')
        },
        {
          path: '/sales/invoices/:id/print',
          name: 'print-sale-invoice-page',
          component: () => import(/* webpackChunkName: "" */ '@/views/admin/sales/invoices/print/printInvoice.vue')
        },
        {
          path: '/purchases/invoices/:id/print',
          name: 'print-purchase-invoice-page',
          component: () => import(/* webpackChunkName: "" */ '@/views/admin/purchases/invoices/print/printInvoice.vue')
        },
        {
          path: '/stores/receipts/:id/print',
          name: 'print-stores-receipt-page',
          component: () => import(/* webpackChunkName: "print-stores-receipt-page" */ '@/views/admin/stores/receipts/receipt/print/printStoresReceipt.vue')
        },
        {
          path: '/stores/receipts/:id/label',
          name: 'stores-receipt-label-page',
          component: () => import(/* webpackChunkName: "stores-receipt-label-page" */ '@/views/admin/stores/receipts/receipt/label/storesReceiptLabel.vue')
        },
        {
          path: '/treasury/payment/:id/print',
          name: 'print-payment-page',
          component: () => import(/* webpackChunkName: "print-payment-page" */ '@/views/admin/accountancy/documents/print/PrintPayment.vue')
        },
        {
          path: '/treasury/receive/:id/print',
          name: 'print-receive-page',
          component: () => import(/* webpackChunkName: "print-receive-page" */ '@/views/admin/accountancy/documents/print/PrintReceive.vue')
        },
        {
          path: '/treasury/internal-funds-transfers/:id/print',
          name: 'print-internal-funds-transfer-page',
          component: () => import(/* webpackChunkName: "print-internal-funds-transfer-page" */ '@/views/admin/accountancy/documents/print/PrintInternalFundsTransfer.vue')
        },
        {
          path: '/treasury/cost/:id/print',
          name: 'print-cost-page',
          component: () => import(/* webpackChunkName: "print-cost-page" */ '@/views/admin/accountancy/documents/print/PrintCost.vue')
        }
      ]
    },
    // Redirect to 404 page, if no match found
    {
      path: '*',
      redirect: '/error-404'
    }
  ]
})

router.afterEach(afterEach)

export default router

router.beforeEach(beforeEach)
