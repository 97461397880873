import store from '@/store/store'
import {getProfile} from '@/http/requests/users/users'
import {checkUserPermissions, getAvatarUrl, getCookie, showLoading} from '../assets/js/functions'
import {getCompanyInfo} from "../http/requests/auth";

function validateUser () {
  return getProfile()
}

function getCompanyInformation () {
  getCompanyInfo().then((response) => {
    const company = response.data
    const clubInfo = {
      clubName: company.company_name,
      clubLogo: company.company_avatar ? getAvatarUrl(company.company_avatar) : ''
    }
    store.dispatch('setting/setClubInfo', clubInfo)
  })
}

export async function beforeEach (to, from, next) {
  /* in this place check user was logged in or not */

  /* enable loading when route changed */
  showLoading()
  // getCompanyInformation()

  try {
    store.dispatch('helper/changePrevRoute', from)
    const accessToken = localStorage.getItem('accessToken') || getCookie('accessToken')
    if (accessToken) {
      await store.dispatch('auth/setAccessToken').then(() => {
        if (to.name === 'page-login') {
          next({name: 'home'})
        }
        validateUser().then((validate) => {
          if (validate.status === 200) {
            store.dispatch('auth/updateUserInfo', validate.data.data)

            const userInfo = store.state.auth.user
            if (userInfo.active_sessions > userInfo.role.allowed_active_sessions && to.name !== 'Profile') {
              next({name: 'Profile'})
            }

            let tableSettings = JSON.parse(validate.data.data.browser_setting)
            // delete tableSettings.tables.payload
            if (tableSettings) {
              store.dispatch('table/setUserBrowserSettings', tableSettings)
            }

            if (to.meta.hasOwnProperty('permission')) {
              if (checkUserPermissions(to.meta.permission)) {
                /*if (store.state.pageHaveChanges) {
                  const answer =
                    window.confirm('شما تغییراتی دارید که ذخیره نشده است, آیا مطمئنید که می خواهید خارج شوید؟');

                  if (answer) {
                    store.dispatch('removePageChanges')
                    next();
                  } else {
                    next(false);
                  }
                } else {
                  next()
                }*/
                next()
              } else {
                next({name: 'page-error-404'})
              }
            } else {
              next()
            }
          } else {
            throw 'auth-error'
          }
        })
      })
    } else if (!to.matched.some(record => record.name === 'page-login')) {
      throw 'auth-error'
    } else {
      next()
    }
  } catch (e) {
    if (!to.matched.some(record => record.name === 'page-login')) {
      next({
        name: 'page-login'
        /* query: {redirectFrom: to.fullPath}*/
      })
      // next(false)
    } else {
      next()
    }
  }
}
